import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IStates {
id:string,
name:string
}

interface IStatesData {
    list?: Array<IStates>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IStatesData = {
    pageNo: 1,
    pageSize: 100,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const statesSlice = createSlice({
    name: "states",
    initialState,
    reducers: {
        setStatesList: (state, _action: PayloadAction<IStatesData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetStatesToInit: (state) => {
            state = initialState;
        },
        setStatesMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setStatesList, resetStatesToInit, setStatesMessage } = statesSlice.actions;

export default statesSlice.reducer;

