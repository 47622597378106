import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUser_Activity {
id:string,
user_id:string,
activity:string,
timestamp:Date
}

interface IUser_ActivityData {
    list?: Array<IUser_Activity>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IUser_ActivityData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const user_activitySlice = createSlice({
    name: "user_activity",
    initialState,
    reducers: {
        setUser_ActivityList: (state, _action: PayloadAction<IUser_ActivityData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetUser_ActivityToInit: (state) => {
            state = initialState;
        },
        setUser_ActivityMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setUser_ActivityList, resetUser_ActivityToInit, setUser_ActivityMessage } = user_activitySlice.actions;

export default user_activitySlice.reducer;

