import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setLoyaltyMessage } from "redux/actions";
import { resetDriversToInit, setDriversList } from "redux/actions";
import { resetVehicle_OwnersToInit, setVehicle_OwnersList } from "redux/actions";
import { resetVehiclesToInit, setVehiclesList } from "redux/actions";

import { getDrivers } from "services/driversService";
import { getVehicle_Owners } from "services/vehicle_ownersService";
import { getVehicles } from "services/vehiclesService";

import { useAppDispatch } from "redux/store";
import { addLoyalty, updateLoyalty } from "services/loyaltyService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const LoyaltyForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',driver_id:'',conductor_id:'',vehicle_owner_id:'',vehicle_id:'',park_id:'',tokens:''};
    const initialValue = action === 'edit' ? row : iValue;
    const driversData = useSelector((state: RootState) => state.drivers);
const vehicle_ownersData = useSelector((state: RootState) => state.vehicle_owners);
const vehiclesData = useSelector((state: RootState) => state.vehicles);

    useEffect(() => {
if (driversData && driversData.list && driversData.list.length === 0) {
            dispatch(resetDriversToInit());
            getDrivers(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setDriversList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setLoyaltyMessage("No Record Found For Drivers"));
                }
              })
        }
if (vehicle_ownersData && vehicle_ownersData.list && vehicle_ownersData.list.length === 0) {
            dispatch(resetVehicle_OwnersToInit());
            getVehicle_Owners(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setVehicle_OwnersList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setLoyaltyMessage("No Record Found For Vehicle_Owners"));
                }
              })
        }
if (vehiclesData && vehiclesData.list && vehiclesData.list.length === 0) {
            dispatch(resetVehiclesToInit());
            getVehicles(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setVehiclesList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setLoyaltyMessage("No Record Found For Vehicles"));
                }
              })
        }
}, [])
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateLoyalty(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setLoyaltyMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setLoyaltyMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addLoyalty(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setLoyaltyMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setLoyaltyMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           tokens: yup.string().required('tokens is required'),
driver_id: yup.string().required('driver_id is required'),
vehicle_owner_id: yup.string().required('vehicle_owner_id is required'),
vehicle_id: yup.string().required('vehicle_id is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Loyalty
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Loyalty</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">tokens</label>
<Form.Control type="text" name="tokens" className="form-control" value={formik.values.tokens}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.tokens && !!formik.errors.tokens}
isValid ={!!formik.touched.tokens && !formik.errors.tokens}
></Form.Control>
{
    formik.errors.tokens && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.tokens}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">driver_id</label>
<Form.Control as="select"  name="driver_id" className="form-control" value={formik.values.driver_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.driver_id && !!formik.errors.driver_id}
isValid ={!!formik.touched.driver_id && !formik.errors.driver_id}
>
<option value={0}>Select Drivers </option> 
{
driversData.list.map((item, i) => {
return <option value={item.id} key={`drivers-${i}`}>{item.name}</option>
})}
</Form.Control>
{
    formik.errors.driver_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.driver_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">vehicle_owner_id</label>
<Form.Control as="select"  name="vehicle_owner_id" className="form-control" value={formik.values.vehicle_owner_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.vehicle_owner_id && !!formik.errors.vehicle_owner_id}
isValid ={!!formik.touched.vehicle_owner_id && !formik.errors.vehicle_owner_id}
>
<option value={0}>Select Vehicle_Owners </option> 
{
vehicle_ownersData.list.map((item, i) => {
return <option value={item.id} key={`vehicle_owners-${i}`}>{item.last_name}</option>
})}
</Form.Control>
{
    formik.errors.vehicle_owner_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.vehicle_owner_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">vehicle_id</label>
<Form.Control as="select"  name="vehicle_id" className="form-control" value={formik.values.vehicle_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.vehicle_id && !!formik.errors.vehicle_id}
isValid ={!!formik.touched.vehicle_id && !formik.errors.vehicle_id}
>
<option value={0}>Select Vehicles </option> 
{
vehiclesData.list.map((item, i) => {
return <option value={item.id} key={`vehicles-${i}`}>{item.registration_number}</option>
})}
</Form.Control>
{
    formik.errors.vehicle_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.vehicle_id}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

