import { APIService } from "services";

export const getUser_Sessions = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllUser_Sessions(pageNo,pageSize);
    }
    else{
        try {
            res = await searchUser_Sessions(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], total_count:0 }
        }
    }
    if(res && res.data && res.data.data){
    return { records:res.data.data.data, total_count:res.data.data.total }
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addUser_Sessions = (data) => {
return APIService.api().post(`/user_sessions`,data)
}
export const updateUser_Sessions = (data) => {
return APIService.api().put(`/user_sessions/${data.id}`,data)
}
export const getAllUser_Sessions = (page,paginator) => {
//return APIService.api().get(`/user_sessions/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/user_sessions`)
}
export const getOneUser_Sessions = (id) => {
return APIService.api().get(`/user_sessions/${id}`)
}
export const searchUser_Sessions = (searchKey,page,paginator) => {
//return APIService.api().get(`/user_sessions/search/${searchKey}/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/user_sessions/search/${searchKey}`)
}
export const deleteUser_Sessions = (id) => {
return APIService.api().delete(`/user_sessions/${id}`)
}
