import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IVehicle_Owners {
id:string,
last_name:string,
first_name:string,
default_mobile_id?:string,
default_email_id?:string,
registered_by?:string,
union_id?:string,
group_id?:string
}

interface IVehicle_OwnersData {
    list?: Array<IVehicle_Owners>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IVehicle_OwnersData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const vehicle_ownersSlice = createSlice({
    name: "vehicle_owners",
    initialState,
    reducers: {
        setVehicle_OwnersList: (state, _action: PayloadAction<IVehicle_OwnersData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetVehicle_OwnersToInit: (state) => {
            state = initialState;
        },
        setVehicle_OwnersMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setVehicle_OwnersList, resetVehicle_OwnersToInit, setVehicle_OwnersMessage } = vehicle_ownersSlice.actions;

export default vehicle_ownersSlice.reducer;

