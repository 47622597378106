import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppDispatch } from "redux/store";
import { tokenAPICALL } from "services/authService";
import { setJWTToken, setUserProfie } from "redux/actions";
import { setError } from "redux/slices/auth";
import { Alert, Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { Constant } from "template/Constant";
import bgImage from "../assets/bg-img3.png";
import styles from './Login.module.scss';

const Login: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const rData = useSelector((state: RootState) => state.authToken);
    const [rememberMe, setRememberMe] = useState(false);

    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        onSubmit: async (values) => {
            const response = await tokenAPICALL(values.username, values.password);
            if (response) {
                dispatch(setUserProfie(response.userProfile));
                dispatch(setJWTToken(response));

                if (rememberMe) {
                    localStorage.setItem('authToken', JSON.stringify(response));
                } else {
                    localStorage.removeItem('authToken');
                }

                navigate('/dashboard', { replace: true });
            } else {
                dispatch(setError("Invalid Credentials"))
            }
        },
        validationSchema: yup.object({
            username: yup.string().trim().required('Username is required'),
            password: yup.string().trim().required('Password is required'),
        }),
    });

    useEffect(() => {
        document.body.style.backgroundImage = `url(${bgImage})`;
    
        const savedToken = localStorage.getItem('authToken');
        if (savedToken) {
            const parsedToken = JSON.parse(savedToken);
            dispatch(setUserProfie(parsedToken.userProfile));
            dispatch(setJWTToken(parsedToken));
            navigate('/dashboard', { replace: true });
        }
    }, [dispatch, navigate])
    
    const handleRememberMeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRememberMe(event.target.checked);
    };

    return (
        <div className={styles['hero-background']}>
            <div className={styles.overlay}></div>
            <div className={styles.hero}>
                <img src="https://s3.eu-west-2.amazonaws.com/openstates.ng.storage/public/setting/setting_Abia-logo.png" alt="Parks Manager Logo"/>
            </div>
            <div className={styles['form-block']}>
             <h3>Login to Parks Manager</h3>
              <Form className="user mt-5" onSubmit={formik.handleSubmit}>
              <Form.Group>
    <Form.Control 
        type="text" 
        name="username" 
        className={styles.input}
        value={formik.values.username}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        isInvalid={!!formik.touched.username && !!formik.errors.username}
        isValid={!!formik.touched.username && !formik.errors.username}
        placeholder="Username" // Add placeholder here
    ></Form.Control>
    {formik.errors.username && (
        <Form.Control.Feedback type="invalid">
            {formik.errors.username}
        </Form.Control.Feedback>
    )}
</Form.Group>
<Form.Group>
    <Form.Control 
        type="password" 
        name="password" 
        className={styles.input} 
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        isInvalid={!!formik.touched.password && !!formik.errors.password}
        isValid={!!formik.touched.password && !formik.errors.password}
        placeholder="Password" // Add placeholder here
    ></Form.Control>
    {formik.errors.password && (
        <Form.Control.Feedback type="invalid">
            {formik.errors.password}
        </Form.Control.Feedback>
    )}
</Form.Group>

<div className="form-group">
    <div className="custom-control custom-checkbox small">
        <input 
            type="checkbox" 
            className="custom-control-input" 
            id="customCheck" 
            checked={rememberMe}
            onChange={handleRememberMeChange}
        />
        <label className="custom-control-label" htmlFor="customCheck">Remember Me</label>
    </div>
</div>
<Button type="submit" className={`btn-user btn-block ${styles.input}`} variant="success">Login</Button>
</Form>
<hr />
{rData.errorMessage ?
    <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">{rData.errorMessage}
        <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => dispatch(setError(''))}>
            <span aria-hidden="true">&times;</span>
        </Button>
    </Alert> : null}
<div className="copyright text-center my-auto">
    <span>Copyright &copy; Parks Manager 2023</span>
</div>
</div>
</div>
);
};

export default Login;
