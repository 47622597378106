import { APIService } from "services";

export const getLga = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllLga(pageNo,pageSize);
    }
    else{
        try {
            res = await searchLga(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], total_count:0 }
        }
    }
    if(res && res.data && res.data.data){
    return { records:res.data.data.data, total_count:res.data.data.total }
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addLga = (data) => {
return APIService.api().post(`/lga`,data)
}
export const updateLga = (data) => {
return APIService.api().put(`/lga/${data.id}`,data)
}
export const getAllLga = (page,paginator) => {
//return APIService.api().get(`/lga/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/lga`)
}
export const getOneLga = (id) => {
return APIService.api().get(`/lga/${id}`)
}
export const searchLga = (searchKey,page,paginator) => {
//return APIService.api().get(`/lga/search/${searchKey}/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/lga/search/${searchKey}`)
}
export const deleteLga = (id) => {
return APIService.api().delete(`/lga/${id}`)
}
