import { APIService } from "services";

export const getVehicle_Details = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllVehicle_Details(pageNo,pageSize);
    }
    else{
        try {
            res = await searchVehicle_Details(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], total_count:0 }
        }
    }
    if(res && res.data && res.data.data){
    return { records:res.data.data.data, total_count:res.data.data.total }
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addVehicle_Details = (data) => {
return APIService.api().post(`/vehicle_details`,data)
}
export const updateVehicle_Details = (data) => {
return APIService.api().put(`/vehicle_details/${data.id}`,data)
}
export const getAllVehicle_Details = (page,paginator) => {
//return APIService.api().get(`/vehicle_details/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/vehicle_details`)
}
export const getOneVehicle_Details = (id) => {
return APIService.api().get(`/vehicle_details/${id}`)
}
export const searchVehicle_Details = (searchKey,page,paginator) => {
//return APIService.api().get(`/vehicle_details/search/${searchKey}/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/vehicle_details/search/${searchKey}`)
}
export const deleteVehicle_Details = (id) => {
return APIService.api().delete(`/vehicle_details/${id}`)
}
