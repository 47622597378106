import { APIService } from "services";

export const getPark_Monitors = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllPark_Monitors(pageNo,pageSize);
    }
    else{
        try {
            res = await searchPark_Monitors(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], total_count:0 }
        }
    }
    if(res && res.data && res.data.data){
    return { records:res.data.data.data, total_count:res.data.data.total }
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addPark_Monitors = (data) => {
return APIService.api().post(`/park_monitors`,data)
}
export const updatePark_Monitors = (data) => {
return APIService.api().put(`/park_monitors/${data.id}`,data)
}
export const getAllPark_Monitors = (page,paginator) => {
//return APIService.api().get(`/park_monitors/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/park_monitors`)
}
export const getOnePark_Monitors = (id) => {
return APIService.api().get(`/park_monitors/${id}`)
}
export const searchPark_Monitors = (searchKey,page,paginator) => {
//return APIService.api().get(`/park_monitors/search/${searchKey}/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/park_monitors/search/${searchKey}`)
}
export const deletePark_Monitors = (id) => {
return APIService.api().delete(`/park_monitors/${id}`)
}
