import { APIService } from "services";

export const getAutoreg_Vehicle_Info = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllAutoreg_Vehicle_Info(pageNo,pageSize);
    }
    else{
        try {
            res = await searchAutoreg_Vehicle_Info(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], total_count:0 }
        }
    }
    if(res && res.data && res.data.data){
    return { records:res.data.data.data, total_count:res.data.data.total }
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addAutoreg_Vehicle_Info = (data) => {
return APIService.api().post(`/autoreg_vehicle_info`,data)
}
export const updateAutoreg_Vehicle_Info = (data) => {
return APIService.api().put(`/autoreg_vehicle_info/${data.id}`,data)
}
export const getAllAutoreg_Vehicle_Info = (page,paginator) => {
//return APIService.api().get(`/autoreg_vehicle_info/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/autoreg_vehicle_info`)
}
export const getOneAutoreg_Vehicle_Info = (id) => {
return APIService.api().get(`/autoreg_vehicle_info/${id}`)
}
export const searchAutoreg_Vehicle_Info = (searchKey,page,paginator) => {
//return APIService.api().get(`/autoreg_vehicle_info/search/${searchKey}/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/autoreg_vehicle_info/search/${searchKey}`)
}
export const deleteAutoreg_Vehicle_Info = (id) => {
return APIService.api().delete(`/autoreg_vehicle_info/${id}`)
}
