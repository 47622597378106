import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setFeedbackMessage } from "redux/actions";
import { resetUsersToInit, setUsersList } from "redux/actions";

import { getUsers } from "services/usersService";

import { useAppDispatch } from "redux/store";
import { addFeedback, updateFeedback } from "services/feedbackService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const FeedbackForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',user_id:'',feedback:'',timestamp:''};
    const initialValue = action === 'edit' ? row : iValue;
    const usersData = useSelector((state: RootState) => state.users);

    useEffect(() => {
if (usersData && usersData.list && usersData.list.length === 0) {
            dispatch(resetUsersToInit());
            getUsers(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setUsersList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setFeedbackMessage("No Record Found For Users"));
                }
              })
        }
}, [])
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateFeedback(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setFeedbackMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setFeedbackMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addFeedback(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setFeedbackMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setFeedbackMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           feedback: yup.string().required('feedback is required'),
timestamp: yup.date().required('timestamp is required'),
user_id: yup.string().required('user_id is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Feedback
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Feedback</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">feedback</label>
<Form.Control type="text" name="feedback" className="form-control" value={formik.values.feedback}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.feedback && !!formik.errors.feedback}
isValid ={!!formik.touched.feedback && !formik.errors.feedback}
></Form.Control>
{
    formik.errors.feedback && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.feedback}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">timestamp</label>
<Form.Control type="text" name="timestamp" className="form-control" value={formik.values.timestamp}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.timestamp && !!formik.errors.timestamp}
isValid ={!!formik.touched.timestamp && !formik.errors.timestamp}
></Form.Control>
{
    formik.errors.timestamp && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.timestamp}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">user_id</label>
<Form.Control as="select"  name="user_id" className="form-control" value={formik.values.user_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.user_id && !!formik.errors.user_id}
isValid ={!!formik.touched.user_id && !formik.errors.user_id}
>
<option value={0}>Select Users </option> 
{
usersData.list.map((item, i) => {
return <option value={item.id} key={`users-${i}`}>{item.last_name}</option>
})}
</Form.Control>
{
    formik.errors.user_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.user_id}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

