import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IRole_Permissions {
role_id:string,
permission_id:string
}

interface IRole_PermissionsData {
    list?: Array<IRole_Permissions>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IRole_PermissionsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const role_permissionsSlice = createSlice({
    name: "role_permissions",
    initialState,
    reducers: {
        setRole_PermissionsList: (state, _action: PayloadAction<IRole_PermissionsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetRole_PermissionsToInit: (state) => {
            state = initialState;
        },
        setRole_PermissionsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setRole_PermissionsList, resetRole_PermissionsToInit, setRole_PermissionsMessage } = role_permissionsSlice.actions;

export default role_permissionsSlice.reducer;

