import { APIService } from "services";

export const getUnions = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllUnions(pageNo,pageSize);
    }
    else{
        try {
            res = await searchUnions(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], total_count:0 }
        }
    }
    if(res && res.data && res.data.data){
    return { records:res.data.data.data, total_count:res.data.data.total }
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addUnions = (data) => {
return APIService.api().post(`/unions`,data)
}
export const updateUnions = (data) => {
return APIService.api().put(`/unions/${data.id}`,data)
}
export const getAllUnions = (page,paginator) => {
//return APIService.api().get(`/unions/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/unions`)
}
export const getOneUnions = (id) => {
return APIService.api().get(`/unions/${id}`)
}
export const searchUnions = (searchKey,page,paginator) => {
//return APIService.api().get(`/unions/search/${searchKey}/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/unions/search/${searchKey}`)
}
export const deleteUnions = (id) => {
return APIService.api().delete(`/unions/${id}`)
}
