import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IVehicle_History {
id:string,
vehicle_id:string,
driver_id:string,
conductor_id:string,
start_date:Date,
end_date:Date
}

interface IVehicle_HistoryData {
    list?: Array<IVehicle_History>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IVehicle_HistoryData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const vehicle_historySlice = createSlice({
    name: "vehicle_history",
    initialState,
    reducers: {
        setVehicle_HistoryList: (state, _action: PayloadAction<IVehicle_HistoryData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetVehicle_HistoryToInit: (state) => {
            state = initialState;
        },
        setVehicle_HistoryMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setVehicle_HistoryList, resetVehicle_HistoryToInit, setVehicle_HistoryMessage } = vehicle_historySlice.actions;

export default vehicle_historySlice.reducer;

