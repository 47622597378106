import { APIService } from "services";

export const getVehicle_Owners = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllVehicle_Owners(pageNo,pageSize);
    }
    else{
        try {
            res = await searchVehicle_Owners(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], total_count:0 }
        }
    }
    if(res && res.data && res.data.data){
    return { records:res.data.data.data, total_count:res.data.data.total }
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addVehicle_Owners = (data) => {
return APIService.api().post(`/vehicle_owners`,data)
}
export const updateVehicle_Owners = (data) => {
return APIService.api().put(`/vehicle_owners/${data.id}`,data)
}
export const getAllVehicle_Owners = (page,paginator) => {
//return APIService.api().get(`/vehicle_owners/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/vehicle_owners`)
}
export const getOneVehicle_Owners = (id) => {
return APIService.api().get(`/vehicle_owners/${id}`)
}
export const searchVehicle_Owners = (searchKey,page,paginator) => {
//return APIService.api().get(`/vehicle_owners/search/${searchKey}/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/vehicle_owners/search/${searchKey}`)
}
export const deleteVehicle_Owners = (id) => {
return APIService.api().delete(`/vehicle_owners/${id}`)
}
