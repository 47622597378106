import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setTariffsMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addTariffs, updateTariffs } from "services/tariffsService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const TariffsForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',name:'',amount:0,duration:'',type:'',category:'',is_compulsory:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateTariffs(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setTariffsMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setTariffsMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addTariffs(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setTariffsMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setTariffsMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           name: yup.string().required('Tariff is required'),
amount: yup.number().required('amount is required'),
duration: yup.string().required('duration is required'),
type: yup.string().required('type is required'),
category: yup.string().required('category is required'),
is_compulsory: yup.boolean().required('is compulsory? is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-success text-capitalize">{action} Tariffs
                    <Button className="btn-icon-split float-right btn-success" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Tariffs</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                    <div className="row md:px-4">
                    <div className="col-lg-6">
                  <Form.Group>
<label className="form -control-label">Tariff</label>
<Form.Control type="text" name="name" className="form-control" value={formik.values.name}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.name && !!formik.errors.name}
isValid ={!!formik.touched.name && !formik.errors.name}
></Form.Control>
{
    formik.errors.name && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.name}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">Amount</label>
<Form.Control type="text" name="amount" className="form-control" value={formik.values.amount}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.amount && !!formik.errors.amount}
isValid ={!!formik.touched.amount && !formik.errors.amount}
></Form.Control>
{
    formik.errors.amount && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.amount}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">Duration</label>
<Form.Control type="text" name="duration" className="form-control" value={formik.values.duration}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.duration && !!formik.errors.duration}
isValid ={!!formik.touched.duration && !formik.errors.duration}
></Form.Control>
{
    formik.errors.duration && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.duration}
    </Form.Control.Feedback>
)}
</Form.Group>
</div>
<div className="col-lg-6">
<Form.Group>
<label className="form -control-label">Type</label>
<Form.Control type="text" name="type" className="form-control" value={formik.values.type}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.type && !!formik.errors.type}
isValid ={!!formik.touched.type && !formik.errors.type}
></Form.Control>
{
    formik.errors.type && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.type}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">Category</label>
<Form.Control type="text" name="category" className="form-control" value={formik.values.category}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.category && !!formik.errors.category}
isValid ={!!formik.touched.category && !formik.errors.category}
></Form.Control>
{
    formik.errors.category && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.category}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">Is Compulsory?</label>
<Form.Control as="select" name="is_compulsory" className="form-control" value={formik.values.is_compulsory}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.is_compulsory && !!formik.errors.is_compulsory}
isValid ={!!formik.touched.is_compulsory && !formik.errors.is_compulsory}
>
    <option value={0}>Select</option>
    <option value={1}>Yes</option>
    <option value={0}>No</option>
</Form.Control>
{
    formik.errors.is_compulsory && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.is_compulsory}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="btn-block" style={{ marginTop: '48px' }} variant="success">Save</Button>
                    </Form.Group>
                    </div>
                </div>
                </Form>
            </Card.Body>
        </Card>
    );
}

