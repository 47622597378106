import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IPark_Monitors {
id:string,
user_id:string,
name:string
}

interface IPark_MonitorsData {
    list?: Array<IPark_Monitors>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IPark_MonitorsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const park_monitorsSlice = createSlice({
    name: "park_monitors",
    initialState,
    reducers: {
        setPark_MonitorsList: (state, _action: PayloadAction<IPark_MonitorsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetPark_MonitorsToInit: (state) => {
            state = initialState;
        },
        setPark_MonitorsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setPark_MonitorsList, resetPark_MonitorsToInit, setPark_MonitorsMessage } = park_monitorsSlice.actions;

export default park_monitorsSlice.reducer;

