import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setConductorsMessage } from "redux/actions";
import { resetUnionsToInit, setUnionsList } from "redux/actions";
import { resetEmailsToInit, setEmailsList } from "redux/actions";
import { resetMobile_NumbersToInit, setMobile_NumbersList } from "redux/actions";
import { resetBadgesToInit, setBadgesList } from "redux/actions";

import { getUnions } from "services/unionsService";
import { getEmails } from "services/emailsService";
import { getMobile_Numbers } from "services/mobile_numbersService";
import { getBadges } from "services/badgesService";

import { useAppDispatch } from "redux/store";
import { addConductors, updateConductors } from "services/conductorsService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const ConductorsForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',name:'',union_id:'',group_id:'',email_id:'',mobile_id:'',badge_id:''};
    const initialValue = action === 'edit' ? row : iValue;
    const unionsData = useSelector((state: RootState) => state.unions);
const emailsData = useSelector((state: RootState) => state.emails);
const mobile_numbersData = useSelector((state: RootState) => state.mobile_numbers);
const badgesData = useSelector((state: RootState) => state.badges);

    useEffect(() => {
if (unionsData && unionsData.list && unionsData.list.length === 0) {
            dispatch(resetUnionsToInit());
            getUnions(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setUnionsList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setConductorsMessage("No Record Found For Unions"));
                }
              })
        }
if (emailsData && emailsData.list && emailsData.list.length === 0) {
            dispatch(resetEmailsToInit());
            getEmails(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setEmailsList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setConductorsMessage("No Record Found For Emails"));
                }
              })
        }
if (mobile_numbersData && mobile_numbersData.list && mobile_numbersData.list.length === 0) {
            dispatch(resetMobile_NumbersToInit());
            getMobile_Numbers(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setMobile_NumbersList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setConductorsMessage("No Record Found For Mobile_Numbers"));
                }
              })
        }
if (badgesData && badgesData.list && badgesData.list.length === 0) {
            dispatch(resetBadgesToInit());
            getBadges(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setBadgesList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setConductorsMessage("No Record Found For Badges"));
                }
              })
        }
}, [])
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateConductors(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setConductorsMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setConductorsMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addConductors(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setConductorsMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setConductorsMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           name: yup.string().required('Conductor name is required'),
union_id: yup.string().required('Union is required'),
email_id: yup.string().required('Email is required'),
mobile_id: yup.string().required('Mobile is required'),
badge_id: yup.string().required('Badge is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-success text-capitalize">{action} Conductors
                    <Button className="btn-icon-split float-right btn-success" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Conductors</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                    <div className="row md:px-4">
                        <div className="col-lg-6">
                  <Form.Group>
<label className="form -control-label">Conductor</label>
<Form.Control type="text" name="name" className="form-control" value={formik.values.name}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.name && !!formik.errors.name}
isValid ={!!formik.touched.name && !formik.errors.name}
></Form.Control>
{
    formik.errors.name && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.name}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">Union</label>
<Form.Control as="select"  name="union_id" className="form-control" value={formik.values.union_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.union_id && !!formik.errors.union_id}
isValid ={!!formik.touched.union_id && !formik.errors.union_id}
>
<option value={0}>Select Unions </option> 
{
unionsData.list.map((item, i) => {
return <option value={item.id} key={`unions-${i}`}>{item.name}</option>
})}
</Form.Control>
{
    formik.errors.union_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.union_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">Email</label>
<Form.Control as="select"  name="email_id" className="form-control" value={formik.values.email_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.email_id && !!formik.errors.email_id}
isValid ={!!formik.touched.email_id && !formik.errors.email_id}
>
<option value={0}>Select Emails </option> 
{
emailsData.list.map((item, i) => {
return <option value={item.id} key={`emails-${i}`}>{item.email}</option>
})}
</Form.Control>
{
    formik.errors.email_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.email_id}
    </Form.Control.Feedback>
)}
</Form.Group>
</div>
<div className="col-lg-6">
<Form.Group>
<label className="form -control-label">Mobile</label>
<Form.Control as="select"  name="mobile_id" className="form-control" value={formik.values.mobile_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.mobile_id && !!formik.errors.mobile_id}
isValid ={!!formik.touched.mobile_id && !formik.errors.mobile_id}
>
<option value={0}>Select Mobile_Numbers </option> 
{
mobile_numbersData.list.map((item, i) => {
return <option value={item.id} key={`mobile_numbers-${i}`}>{item.number}</option>
})}
</Form.Control>
{
    formik.errors.mobile_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.mobile_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">Badge</label>
<Form.Control as="select"  name="badge_id" className="form-control" value={formik.values.badge_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.badge_id && !!formik.errors.badge_id}
isValid ={!!formik.touched.badge_id && !formik.errors.badge_id}
>
<option value={0}>Select Badges </option> 
{
badgesData.list.map((item, i) => {
return <option value={item.id} key={`badges-${i}`}>{item.badge_category}</option>
})}
</Form.Control>
{
    formik.errors.badge_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.badge_id}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="btn-block" style={{ marginTop: '48px' }} variant="success">Save</Button>
                    </Form.Group>
                    </div></div>
                </Form>
            </Card.Body>
        </Card>
    );
}

