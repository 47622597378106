export const MenuItems = [
    {
      title: 'Dashboard',
      path: '/dashboard',
      icon: 'fas fa-fw fa-tachometer-alt',
      subMenu: []
    },
    {
      title: 'Park Management',
      path: '/parks',
      icon: 'fas fa-fw fa-table',
      subMenu: [
        { title: 'Parks', path: '/parks', icon: 'fas fa-fw fa-park' },
        { title: 'Tariff Management', path: '/tariffs', icon: 'fas fa-fw fa-money-bill-wave' },
        { title: 'Park Monitors', path: '/park_monitors', icon: 'fas fa-fw fa-video' },
        { title: 'Park Payments', path: '/park_payments', icon: 'fas fa-fw fa-credit-card' }
      ]
    },
    {
      title: 'User Management',
      path: '/users',
      icon: 'fas fa-fw fa-user',
      subMenu: [
        { title: 'Vehicle Owners', path: '/vehicle_owners', icon: 'fas fa-fw fa-taxi' },
        { title: 'Users', path: '/users', icon: 'fas fa-fw fa-users' },
        { title: 'Drivers', path: '/drivers', icon: 'fas fa-fw fa-car' },
        { title: 'Conductors', path: '/conductors', icon: 'fas fa-fw fa-user-friends' },
        { title: 'Employees', path: '/employees', icon: 'fas fa-fw fa-users-cog' }
      ]
    },
    {
      title: 'Vehicle Management',
      path: '/vehicles',
      icon: 'fas fa-fw fa-table',
      subMenu: [
        { title: 'Vehicles', path: '/vehicles', icon: 'fas fa-fw fa-car' },
        { title: 'Vehicle History', path: '/vehicle_history', icon: 'fas fa-fw fa-history' },
        { title: 'Vehicle Details', path: '/vehicle_details', icon: 'fas fa-fw fa-info-circle' },
        { title: 'Autoreg Vehicle Info', path: '/autoreg_vehicle_info', icon: 'fas fa-fw fa-car-alt' }
      ]
    },
    {
      title: 'Loyalty Program',
      path: '/loyalty',
      icon: 'fas fa-fw fa-table',
      subMenu: []
    },
    {
      title: 'Badges',
      path: '/badges',
      icon: 'fas fa-fw fa-table',
      subMenu: []
    },
    {
      title: 'Tickets',
      path: '/tickets',
      icon: 'fas fa-fw fa-table',
      subMenu: []
    },
    {
      title: 'Unions',
      path: '/unions',
      icon: 'fas fa-fw fa-table',
      subMenu: []
    },
    {
      title: 'File Upload',
      path: '/upload',
      icon: 'fas fa-fw fa-upload',
      subMenu: []
    },
    {
      title: 'System Information',
      path: '/system_info',
      icon: 'fas fa-fw fa-info',
      subMenu: [
        { title: 'User Sessions', path: '/user_sessions', icon: 'fas fa-fw fa-clock' },
        { title: 'User Logs', path: '/user_logs', icon: 'fas fa-fw fa-file-alt' },
        { title: 'User Activity', path: '/user_activity', icon: 'fas fa-fw fa-chart-line' },
        { title: 'Feedback', path: '/feedback', icon: 'fas fa-fw fa-comment' },
        { title: 'OTP', path: '/otp', icon: 'fas fa-fw fa-key' },
        { title: 'Password Resets', path: '/password_resets', icon: 'fas fa-fw fa-lock' }
      ]
    },
    {
      title: 'Contact Information',
      path: '/contact_info',
      icon: 'fas fa-fw fa-address-book',
      subMenu: [
        { title: 'Mobile Numbers', path: '/mobile_numbers', icon: 'fas fa-fw fa-mobile-alt' },
        { title: 'Emails', path: '/emails', icon: 'fas fa-fw fa-envelope' },
        { title: 'City', path: '/city', icon: 'fas fa-fw fa-city' },
        { title: 'LGA', path: '/lga', icon: 'fas fa-fw fa-map-marker-alt' }
      ]
    },
    {
      title: 'States',
      path: '/states',
      icon: 'fas fa-fw fa-table',
      subMenu: []
    },
    {
      title: 'Roles',
      path: '/roles',
      icon: 'fas fa-fw fa-table',
      subMenu: [
        { title: 'Role Permissions', path: '/role_permissions', icon: 'fas fa-fw fa-user-lock' }
      ]
    },
    {
      title: 'Permissions',
      path: '/permissions',
      icon: 'fas fa-fw fa-table',
      subMenu: []
    },
    {
      title: 'Payments',
      path: '/payments',
      icon: 'fas fa-fw fa-table',
      subMenu: []
    },
    {
      title: 'Groups',
      path: '/groups',
      icon: 'fas fa-fw fa-table',
      subMenu: []
    }
  ];  