import { APIService } from "services";

export const getInvoices = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllInvoices(pageNo,pageSize);
    }
    else{
        try {
            res = await searchInvoices(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], total_count:0 }
        }
    }
    if(res && res.data && res.data.data){
    return { records:res.data.data.data, total_count:res.data.data.total }
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addInvoices = (data) => {
return APIService.api().post(`/invoices`,data)
}
export const updateInvoices = (data) => {
return APIService.api().put(`/invoices/${data.id}`,data)
}
export const getAllInvoices = (page,paginator) => {
//return APIService.api().get(`/invoices/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/invoices`)
}
export const getOneInvoices = (id) => {
return APIService.api().get(`/invoices/${id}`)
}
export const searchInvoices = (searchKey,page,paginator) => {
//return APIService.api().get(`/invoices/search/${searchKey}/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/invoices/search/${searchKey}`)
}
export const deleteInvoices = (id) => {
return APIService.api().delete(`/invoices/${id}`)
}
