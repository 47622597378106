import { APIService } from "services";

export const getVehicle_History = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllVehicle_History(pageNo,pageSize);
    }
    else{
        try {
            res = await searchVehicle_History(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], total_count:0 }
        }
    }
    if(res && res.data && res.data.data){
    return { records:res.data.data.data, total_count:res.data.data.total }
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addVehicle_History = (data) => {
return APIService.api().post(`/vehicle_history`,data)
}
export const updateVehicle_History = (data) => {
return APIService.api().put(`/vehicle_history/${data.id}`,data)
}
export const getAllVehicle_History = (page,paginator) => {
//return APIService.api().get(`/vehicle_history/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/vehicle_history`)
}
export const getOneVehicle_History = (id) => {
return APIService.api().get(`/vehicle_history/${id}`)
}
export const searchVehicle_History = (searchKey,page,paginator) => {
//return APIService.api().get(`/vehicle_history/search/${searchKey}/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/vehicle_history/search/${searchKey}`)
}
export const deleteVehicle_History = (id) => {
return APIService.api().delete(`/vehicle_history/${id}`)
}
