import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setUsersMessage } from "redux/actions";
import { resetRolesToInit, setRolesList } from "redux/actions";
import { resetMobile_NumbersToInit, setMobile_NumbersList } from "redux/actions";
import { resetEmailsToInit, setEmailsList } from "redux/actions";
import { resetUsersToInit, setUsersList } from "redux/actions";

import { getRoles } from "services/rolesService";
import { getMobile_Numbers } from "services/mobile_numbersService";
import { getEmails } from "services/emailsService";
import { getUsers } from "services/usersService";

import { useAppDispatch } from "redux/store";
import { addUsers, updateUsers } from "services/usersService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const UsersForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',last_name:'',first_name:'',role_id:'',park_id:'',address:'',lga_id:'',group_id:'',default_mobile_id:'',default_email_id:'',registered_by:'',username:'',email:'',password:''};
    const initialValue = action === 'edit' ? row : iValue;
    const rolesData = useSelector((state: RootState) => state.roles);
const mobile_numbersData = useSelector((state: RootState) => state.mobile_numbers);
const emailsData = useSelector((state: RootState) => state.emails);
const usersData = useSelector((state: RootState) => state.users);

    useEffect(() => {
if (rolesData && rolesData.list && rolesData.list.length === 0) {
            dispatch(resetRolesToInit());
            getRoles(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setRolesList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setUsersMessage("No Record Found For Roles"));
                }
              })
        }
if (mobile_numbersData && mobile_numbersData.list && mobile_numbersData.list.length === 0) {
            dispatch(resetMobile_NumbersToInit());
            getMobile_Numbers(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setMobile_NumbersList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setUsersMessage("No Record Found For Mobile_Numbers"));
                }
              })
        }
if (emailsData && emailsData.list && emailsData.list.length === 0) {
            dispatch(resetEmailsToInit());
            getEmails(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setEmailsList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setUsersMessage("No Record Found For Emails"));
                }
              })
        }
if (usersData && usersData.list && usersData.list.length === 0) {
            dispatch(resetUsersToInit());
            getUsers(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setUsersList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setUsersMessage("No Record Found For Users"));
                }
              })
        }
}, [])
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateUsers(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setUsersMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setUsersMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addUsers(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setUsersMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setUsersMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           id: yup.string().required('Id is required'),
last_name: yup.string().required('Last name is required'),
first_name: yup.string().required('First name is required'),
address: yup.string().nullable(),
username: yup.string().required('Username is required'),
email: yup.string().required('Email is required'),
password: yup.string().required('Password is required'),
role_id: yup.string().required('Role is required'),
default_mobile_id: yup.string().required('Mobile No. is required'),
//default_email_id: yup.string().required('default_email_id is required'),
//registered_by: yup.string().required('registered_by is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-success text-capitalize">{action} Users
                    <Button className="btn-icon-split float-right btn-success" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Users</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                    <div className="row md:px-4">
                    <div className="col-lg-6">
                  <Form.Group>
<label className="form -control-label">Id</label>
<Form.Control type="text" name="id" className="form-control" value={formik.values.id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.id && !!formik.errors.id}
isValid ={!!formik.touched.id && !formik.errors.id}
></Form.Control>
{
    formik.errors.id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">Last name</label>
<Form.Control type="text" name="last_name" className="form-control" value={formik.values.last_name}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.last_name && !!formik.errors.last_name}
isValid ={!!formik.touched.last_name && !formik.errors.last_name}
></Form.Control>
{
    formik.errors.last_name && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.last_name}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">First name</label>
<Form.Control type="text" name="first_name" className="form-control" value={formik.values.first_name}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.first_name && !!formik.errors.first_name}
isValid ={!!formik.touched.first_name && !formik.errors.first_name}
></Form.Control>
{
    formik.errors.first_name && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.first_name}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">Address</label>
<Form.Control type="text" name="address" className="form-control" value={formik.values.address}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.address && !!formik.errors.address}
isValid ={!!formik.touched.address && !formik.errors.address}
></Form.Control>
{
    formik.errors.address && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.address}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">Username</label>
<Form.Control type="text" name="username" className="form-control" value={formik.values.username}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.username && !!formik.errors.username}
isValid ={!!formik.touched.username && !formik.errors.username}
></Form.Control>
{
    formik.errors.username && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.username}
    </Form.Control.Feedback>
)}
</Form.Group>
</div>
<div className="col-lg-6">
<Form.Group>
<label className="form -control-label">Email</label>
<Form.Control type="text" name="email" className="form-control" value={formik.values.email}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.email && !!formik.errors.email}
isValid ={!!formik.touched.email && !formik.errors.email}
></Form.Control>
{
    formik.errors.email && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.email}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">Password</label>
<Form.Control type="password" name="password" className="form-control" value={formik.values.password}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.password && !!formik.errors.password}
isValid ={!!formik.touched.password && !formik.errors.password}
></Form.Control>
{
    formik.errors.password && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.password}
    </Form.Control.Feedback>
)}
</Form.Group>
{/**<Form.Group>
<label className="form -control-label">DEFAULT MOBILE NO</label>
<Form.Control type="text" name="default_mobile_id" className="form-control" value={formik.values.default_mobile_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.default_mobile_id && !!formik.errors.default_mobile_id}
isValid ={!!formik.touched.default_mobile_id && !formik.errors.default_mobile_id}
></Form.Control>
{
    formik.errors.default_mobile_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.default_mobile_id}
    </Form.Control.Feedback>
)}
    </Form.Group>*/}
<Form.Group>
<label className="form -control-label">Role</label>
<Form.Control as="select"  name="role_id" className="form-control" value={formik.values.role_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.role_id && !!formik.errors.role_id}
isValid ={!!formik.touched.role_id && !formik.errors.role_id}
>
<option value={0}>Select Roles </option> 
{
rolesData.list.map((item, i) => {
return <option value={item.id} key={`roles-${i}`}>{item.name}</option>
})}
</Form.Control>
{
    formik.errors.role_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.role_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">Default Mobile No</label>
<Form.Control as="select"  name="default_mobile_id" className="form-control" value={formik.values.default_mobile_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.default_mobile_id && !!formik.errors.default_mobile_id}
isValid ={!!formik.touched.default_mobile_id && !formik.errors.default_mobile_id}
>
<option value={0}>Select Mobile number </option> 
{
mobile_numbersData.list.map((item, i) => {
return <option value={item.id} key={`mobile_numbers-${i}`}>{item.number}</option>
})}
</Form.Control>
{
    formik.errors.default_mobile_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.default_mobile_id}
    </Form.Control.Feedback>
)}
</Form.Group>
{/**<Form.Group>
<label className="form -control-label">default_email_id</label>
<Form.Control as="select"  name="default_email_id" className="form-control" value={formik.values.default_email_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.default_email_id && !!formik.errors.default_email_id}
isValid ={!!formik.touched.default_email_id && !formik.errors.default_email_id}
>
<option value={0}>Select Emails </option> 
{
emailsData.list.map((item, i) => {
return <option value={item.id} key={`emails-${i}`}>{item.email}</option>
})}
</Form.Control>
{
    formik.errors.default_email_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.default_email_id}
    </Form.Control.Feedback>
)}
    </Form.Group>
<Form.Group>
<label className="form -control-label">registered_by</label>
<Form.Control as="select"  name="registered_by" className="form-control" value={formik.values.registered_by}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.registered_by && !!formik.errors.registered_by}
isValid ={!!formik.touched.registered_by && !formik.errors.registered_by}
>
<option value={0}>Select Users </option> 
{
usersData.list.map((item, i) => {
return <option value={item.id} key={`users-${i}`}>{item.last_name}</option>
})}
</Form.Control>
{
    formik.errors.registered_by && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.registered_by}
    </Form.Control.Feedback>
)}
    </Form.Group>*/}

                    <Form.Group>
                        <Button type="submit" className="btn-block" style={{ marginTop: '48px' }} variant="success">Save</Button>
                    </Form.Group>
                    </div></div>
                </Form>
            </Card.Body>
        </Card>
    );
}

