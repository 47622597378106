import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setTicketsMessage } from "redux/actions";
import { resetVehiclesToInit, setVehiclesList } from "redux/actions";
import { resetParksToInit, setParksList } from "redux/actions";
import { resetPark_MonitorsToInit, setPark_MonitorsList } from "redux/actions";
import { resetTariffsToInit, setTariffsList } from "redux/actions";

import { getVehicles } from "services/vehiclesService";
import { getParks } from "services/parksService";
import { getPark_Monitors } from "services/park_monitorsService";
import { getTariffs } from "services/tariffsService";

import { useAppDispatch } from "redux/store";
import { addTickets, updateTickets } from "services/ticketsService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const TicketsForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',vehicle_id:'',park_id:'',park_monitor_id:'',plate_number:'',ticket_date:'',tariff_id:'',ticket_amount:0};
    const initialValue = action === 'edit' ? row : iValue;
    const vehiclesData = useSelector((state: RootState) => state.vehicles);
const parksData = useSelector((state: RootState) => state.parks);
const park_monitorsData = useSelector((state: RootState) => state.park_monitors);
const tariffsData = useSelector((state: RootState) => state.tariffs);

    useEffect(() => {
if (vehiclesData && vehiclesData.list && vehiclesData.list.length === 0) {
            dispatch(resetVehiclesToInit());
            getVehicles(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setVehiclesList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setTicketsMessage("No Record Found For Vehicles"));
                }
              })
        }
if (parksData && parksData.list && parksData.list.length === 0) {
            dispatch(resetParksToInit());
            getParks(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setParksList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setTicketsMessage("No Record Found For Parks"));
                }
              })
        }

if (park_monitorsData && park_monitorsData.list && park_monitorsData.list.length === 0) {
            dispatch(resetPark_MonitorsToInit());
            getPark_Monitors(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setPark_MonitorsList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setTicketsMessage("No Record Found For Park monitors"));
                }
              })
        }

if (tariffsData && tariffsData.list && tariffsData.list.length === 0) {
            dispatch(resetTariffsToInit());
            getTariffs(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setTariffsList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setTicketsMessage("No Record Found For Park monitors"));
                }
              })
        }
}, [])
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateTickets(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setTicketsMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setTicketsMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addTickets(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setTicketsMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setTicketsMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           plate_number: yup.string().required('plate number is required'),
ticket_date: yup.date().required('ticket date is required'),
ticket_amount: yup.number().required('ticket amount is required'),
vehicle_id: yup.string().required('vehicle is required'),
park_id: yup.string().required('park is required'),
tariff_id: yup.string().required('tariff is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-success text-capitalize">{action} Tickets
                    <Button className="btn-icon-split float-right btn-success" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Tickets</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                    <div className="row md:px-4">
                        <div className="col-lg-6">
                  <Form.Group>
<label className="form -control-label">Plate Number</label>
<Form.Control type="text" name="plate_number" className="form-control" value={formik.values.plate_number}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.plate_number && !!formik.errors.plate_number}
isValid ={!!formik.touched.plate_number && !formik.errors.plate_number}
></Form.Control>
{
    formik.errors.plate_number && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.plate_number}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">Ticket date</label>
<Form.Control type="date" name="ticket_date" className="form-control" value={formik.values.ticket_date}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.ticket_date && !!formik.errors.ticket_date}
isValid ={!!formik.touched.ticket_date && !formik.errors.ticket_date}
></Form.Control>
{
    formik.errors.ticket_date && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.ticket_date}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">Ticket amount</label>
<Form.Control type="text" name="ticket_amount" className="form-control" value={formik.values.ticket_amount}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.ticket_amount && !!formik.errors.ticket_amount}
isValid ={!!formik.touched.ticket_amount && !formik.errors.ticket_amount}
></Form.Control>
{
    formik.errors.ticket_amount && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.ticket_amount}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">Tariff</label>
<Form.Control as="select"  name="tariff_id" className="form-control" value={formik.values.tariff_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.tariff_id && !!formik.errors.tariff_id}
isValid ={!!formik.touched.tariff_id && !formik.errors.tariff_id}
>
<option value={0}>Select Tariff </option> 
{
tariffsData.list.map((item, i) => {
return <option value={item.id} key={`tariffs-${i}`}>{item.name}</option>
})}
</Form.Control>
{
    formik.errors.tariff_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.tariff_id}
    </Form.Control.Feedback>
)}
</Form.Group>
</div>
<div className="col-lg-6">
<Form.Group>
<label className="form -control-label">Park Monitor</label>
<Form.Control as="select"  name="park_monitor_id" className="form-control" value={formik.values.park_monitor_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.park_monitor_id && !!formik.errors.park_monitor_id}
isValid ={!!formik.touched.park_monitor_id && !formik.errors.park_monitor_id}
>
<option value={0}>Select Park Monitor </option> 
{
park_monitorsData.list.map((item, i) => {
return <option value={item.id} key={`park-monitors-${i}`}>{item.name}</option>
})}
</Form.Control>
{
    formik.errors.park_monitor_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.park_monitor_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">Vehicle</label>
<Form.Control as="select"  name="vehicle_id" className="form-control" value={formik.values.vehicle_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.vehicle_id && !!formik.errors.vehicle_id}
isValid ={!!formik.touched.vehicle_id && !formik.errors.vehicle_id}
>
<option value={0}>Select Vehicles </option> 
{
vehiclesData.list.map((item, i) => {
return <option value={item.id} key={`vehicles-${i}`}>{item.registration_number}</option>
})}
</Form.Control>
{
    formik.errors.vehicle_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.vehicle_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">Park</label>
<Form.Control as="select"  name="park_id" className="form-control" value={formik.values.park_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.park_id && !!formik.errors.park_id}
isValid ={!!formik.touched.park_id && !formik.errors.park_id}
>
<option value={0}>Select Parks </option> 
{
parksData.list.map((item, i) => {
return <option value={item.id} key={`parks-${i}`}>{item.name}</option>
})}
</Form.Control>
{
    formik.errors.park_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.park_id}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="btn-block" style={{ marginTop: '48px' }} variant="success">Save</Button>
                    </Form.Group>
                    </div></div>
                </Form>
            </Card.Body>
        </Card>
    );
}

