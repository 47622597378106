import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IEmployees {
id:string,
last_name:string,
first_name:string,
role_id:string,
park_id?:string,
address?:string,
lga_id?:string,
group_id?:string,
default_mobile_id?:string,
default_email_id?:string,
registered_by?:string
}

interface IEmployeesData {
    list?: Array<IEmployees>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IEmployeesData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const employeesSlice = createSlice({
    name: "employees",
    initialState,
    reducers: {
        setEmployeesList: (state, _action: PayloadAction<IEmployeesData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetEmployeesToInit: (state) => {
            state = initialState;
        },
        setEmployeesMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setEmployeesList, resetEmployeesToInit, setEmployeesMessage } = employeesSlice.actions;

export default employeesSlice.reducer;

