import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setVehiclesMessage } from "redux/actions";
import { resetVehicle_OwnersToInit, setVehicle_OwnersList } from "redux/actions";
import { resetParksToInit, setParksList } from "redux/actions";

import { getVehicle_Owners } from "services/vehicle_ownersService";
import { getParks } from "services/parksService";

import { useAppDispatch } from "redux/store";
import { addVehicles, updateVehicles } from "services/vehiclesService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const VehiclesForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',registration_number:'',owner_id:'',park_id:'',type:'',operation_scope:'',registered_by:'',union_id:'',group_id:''};
    const initialValue = action === 'edit' ? row : iValue;
    const vehicle_ownersData = useSelector((state: RootState) => state.vehicle_owners);
const parksData = useSelector((state: RootState) => state.parks);

    useEffect(() => {
if (vehicle_ownersData && vehicle_ownersData.list && vehicle_ownersData.list.length === 0) {
            dispatch(resetVehicle_OwnersToInit());
            getVehicle_Owners(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setVehicle_OwnersList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setVehiclesMessage("No Record Found For Vehicle_Owners"));
                }
              })
        }
if (parksData && parksData.list && parksData.list.length === 0) {
            dispatch(resetParksToInit());
            getParks(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setParksList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setVehiclesMessage("No Record Found For Parks"));
                }
              })
        }
}, [])
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateVehicles(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setVehiclesMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setVehiclesMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addVehicles(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setVehiclesMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setVehiclesMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           registration_number: yup.string().required('registration_number is required'),
type: yup.string().required('type is required'),
operation_scope: yup.string().required('operation_scope is required'),
owner_id: yup.string().required('owner_id is required'),
park_id: yup.string().required('park_id is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-success text-capitalize">{action} Vehicles
                    <Button className="btn-icon-split float-right btn-success" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Vehicles</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                    <div className="row md:px-4">
                    <div className="col-lg-6">
                  <Form.Group>
<label className="form -control-label">Registration number</label>
<Form.Control type="text" name="registration_number" className="form-control" value={formik.values.registration_number}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.registration_number && !!formik.errors.registration_number}
isValid ={!!formik.touched.registration_number && !formik.errors.registration_number}
></Form.Control>
{
    formik.errors.registration_number && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.registration_number}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">Type</label>
<Form.Control type="text" name="type" className="form-control" value={formik.values.type}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.type && !!formik.errors.type}
isValid ={!!formik.touched.type && !formik.errors.type}
></Form.Control>
{
    formik.errors.type && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.type}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">Operation scope</label>
<Form.Control type="text" name="operation_scope" className="form-control" value={formik.values.operation_scope}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.operation_scope && !!formik.errors.operation_scope}
isValid ={!!formik.touched.operation_scope && !formik.errors.operation_scope}
></Form.Control>
{
    formik.errors.operation_scope && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.operation_scope}
    </Form.Control.Feedback>
)}
</Form.Group>
</div>
<div className="col-lg-6">
<Form.Group>
<label className="form -control-label">Owner</label>
<Form.Control as="select"  name="owner_id" className="form-control" value={formik.values.owner_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.owner_id && !!formik.errors.owner_id}
isValid ={!!formik.touched.owner_id && !formik.errors.owner_id}
>
<option value={0}>Select Vehicle_Owners </option> 
{
vehicle_ownersData.list.map((item, i) => {
return <option value={item.id} key={`vehicle_owners-${i}`}>{item.last_name}</option>
})}
</Form.Control>
{
    formik.errors.owner_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.owner_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">Park</label>
<Form.Control as="select"  name="park_id" className="form-control" value={formik.values.park_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.park_id && !!formik.errors.park_id}
isValid ={!!formik.touched.park_id && !formik.errors.park_id}
>
<option value={0}>Select Parks </option> 
{
parksData.list.map((item, i) => {
return <option value={item.id} key={`parks-${i}`}>{item.name}</option>
})}
</Form.Control>
{
    formik.errors.park_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.park_id}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="btn-block" style={{ marginTop: '48px' }} variant="success">Save</Button>
                    </Form.Group>
                    </div>
                </div>
                </Form>
            </Card.Body>
        </Card>
    );
}

