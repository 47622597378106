import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IOtp {
id:string,
user_id:string,
otp:string,
otp_type:string,
expiry_time:Date,
created_at:Date
}

interface IOtpData {
    list?: Array<IOtp>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IOtpData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const otpSlice = createSlice({
    name: "otp",
    initialState,
    reducers: {
        setOtpList: (state, _action: PayloadAction<IOtpData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetOtpToInit: (state) => {
            state = initialState;
        },
        setOtpMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setOtpList, resetOtpToInit, setOtpMessage } = otpSlice.actions;

export default otpSlice.reducer;

