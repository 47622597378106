import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IAutoreg_Vehicle_Info {
id:string,
vehicle_id:string,
chassis_number:string,
vehicle_type:string,
plate_number:string,
engine_number:string,
model:string,
vehicle_make:string,
engine_capacity:number,
manufacture_year?:string,
timestamp:Date,
registered_by?:string
}

interface IAutoreg_Vehicle_InfoData {
    list?: Array<IAutoreg_Vehicle_Info>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IAutoreg_Vehicle_InfoData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const autoreg_vehicle_infoSlice = createSlice({
    name: "autoreg_vehicle_info",
    initialState,
    reducers: {
        setAutoreg_Vehicle_InfoList: (state, _action: PayloadAction<IAutoreg_Vehicle_InfoData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetAutoreg_Vehicle_InfoToInit: (state) => {
            state = initialState;
        },
        setAutoreg_Vehicle_InfoMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setAutoreg_Vehicle_InfoList, resetAutoreg_Vehicle_InfoToInit, setAutoreg_Vehicle_InfoMessage } = autoreg_vehicle_infoSlice.actions;

export default autoreg_vehicle_infoSlice.reducer;

