import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setBadgesMessage } from "redux/actions";
import { resetUsersToInit, setUsersList } from "redux/actions";

import { getUsers } from "services/usersService";

import { useAppDispatch } from "redux/store";
import { addBadges, updateBadges } from "services/badgesService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const BadgesForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',badge_category:'',issued_date:'',expiry_date:'',user_id:''};
    const initialValue = action === 'edit' ? row : iValue;
    const usersData = useSelector((state: RootState) => state.users);

    useEffect(() => {
if (usersData && usersData.list && usersData.list.length === 0) {
            dispatch(resetUsersToInit());
            getUsers(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setUsersList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setBadgesMessage("No Record Found For Users"));
                }
              })
        }
}, [])
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateBadges(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setBadgesMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setBadgesMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addBadges(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setBadgesMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setBadgesMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           badge_category: yup.string().required('badge_category is required'),
issued_date: yup.date().required('issued_date is required'),
expiry_date: yup.date().required('expiry_date is required'),
user_id: yup.string().required('user_id is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-success text-capitalize">{action} Badges
                    <Button className="btn-icon-split float-right btn-success" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Badges</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                    <div className="row md:px-4">
                        <div className="col-lg-6">
                  <Form.Group>
<label className="form -control-label">Badge category</label>
<Form.Control type="text" name="badge_category" className="form-control" value={formik.values.badge_category}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.badge_category && !!formik.errors.badge_category}
isValid ={!!formik.touched.badge_category && !formik.errors.badge_category}
></Form.Control>
{
    formik.errors.badge_category && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.badge_category}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">issued date</label>
<Form.Control type="text" name="issued_date" className="form-control" value={formik.values.issued_date}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.issued_date && !!formik.errors.issued_date}
isValid ={!!formik.touched.issued_date && !formik.errors.issued_date}
></Form.Control>
{
    formik.errors.issued_date && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.issued_date}
    </Form.Control.Feedback>
)}
</Form.Group>
</div>
<div className="col-lg-6">
<Form.Group>
<label className="form -control-label">Expiry date</label>
<Form.Control type="text" name="expiry_date" className="form-control" value={formik.values.expiry_date}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.expiry_date && !!formik.errors.expiry_date}
isValid ={!!formik.touched.expiry_date && !formik.errors.expiry_date}
></Form.Control>
{
    formik.errors.expiry_date && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.expiry_date}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">User</label>
<Form.Control as="select"  name="user_id" className="form-control" value={formik.values.user_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.user_id && !!formik.errors.user_id}
isValid ={!!formik.touched.user_id && !formik.errors.user_id}
>
<option value={0}>Select Users </option> 
{
usersData.list.map((item, i) => {
return <option value={item.id} key={`users-${i}`}>{item.last_name}</option>
})}
</Form.Control>
{
    formik.errors.user_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.user_id}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="btn-block" style={{ marginTop: '48px' }} variant="success">Save</Button>
                    </Form.Group>
                    </div></div>
                </Form>
            </Card.Body>
        </Card>
    );
}

