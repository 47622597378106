import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ITariffs {
id:string,
name:string,
amount:number,
duration:string,
type:string,
category:string,
is_compulsory:boolean
}

interface ITariffsData {
    list?: Array<ITariffs>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: ITariffsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const tariffsSlice = createSlice({
    name: "tariffs",
    initialState,
    reducers: {
        setTariffsList: (state, _action: PayloadAction<ITariffsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetTariffsToInit: (state) => {
            state = initialState;
        },
        setTariffsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setTariffsList, resetTariffsToInit, setTariffsMessage } = tariffsSlice.actions;

export default tariffsSlice.reducer;

