import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IPark_Payments {
id:string,
park_id:string,
tariff_id:string,
amount:number
}

interface IPark_PaymentsData {
    list?: Array<IPark_Payments>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IPark_PaymentsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const park_paymentsSlice = createSlice({
    name: "park_payments",
    initialState,
    reducers: {
        setPark_PaymentsList: (state, _action: PayloadAction<IPark_PaymentsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetPark_PaymentsToInit: (state) => {
            state = initialState;
        },
        setPark_PaymentsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setPark_PaymentsList, resetPark_PaymentsToInit, setPark_PaymentsMessage } = park_paymentsSlice.actions;

export default park_paymentsSlice.reducer;

