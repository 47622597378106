import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from "./login";
import Register from "./register";
import AuthenticatedRoute from "components/auth";
import { Dashboard } from "components/dashboard";
import { FileUpload } from "components/upload";
import { NotFound } from "./404";
import { Vehicles, Vehicle_Owners, Vehicle_History, Vehicle_Details, Users, User_Sessions, User_Logs, User_Activity, Unions, Tickets, Tariffs, States, Roles, Role_Permissions, Permissions, Payments, Password_Resets, Parks, Park_Payments, Park_Monitors, Otp, Mobile_Numbers, Loyalty, Lga, Invoices, Groups, Feedback, Employees, Emails, Drivers, Conductors, City, Badges, Autoreg_Vehicle_Info} from "components";
const Pages: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />}>
        </Route>
        <Route path="/register" element={<Register />}>
        </Route>
        <Route path="/dashboard" element={<AuthenticatedRoute element={<Dashboard />} />}></Route>
        <Route path="/upload" element={<AuthenticatedRoute element={<FileUpload />} />}></Route>
        <Route path="/vehicles" element={<AuthenticatedRoute element={<Vehicles />} />}></Route>
<Route path="/vehicle_owners" element={<AuthenticatedRoute element={<Vehicle_Owners />} />}></Route>
<Route path="/vehicle_history" element={<AuthenticatedRoute element={<Vehicle_History />} />}></Route>
<Route path="/vehicle_details" element={<AuthenticatedRoute element={<Vehicle_Details />} />}></Route>
<Route path="/users" element={<AuthenticatedRoute element={<Users />} />}></Route>
<Route path="/user_sessions" element={<AuthenticatedRoute element={<User_Sessions />} />}></Route>
<Route path="/user_logs" element={<AuthenticatedRoute element={<User_Logs />} />}></Route>
<Route path="/user_activity" element={<AuthenticatedRoute element={<User_Activity />} />}></Route>
<Route path="/unions" element={<AuthenticatedRoute element={<Unions />} />}></Route>
<Route path="/tickets" element={<AuthenticatedRoute element={<Tickets />} />}></Route>
<Route path="/tariffs" element={<AuthenticatedRoute element={<Tariffs />} />}></Route>
<Route path="/states" element={<AuthenticatedRoute element={<States />} />}></Route>
<Route path="/roles" element={<AuthenticatedRoute element={<Roles />} />}></Route>
<Route path="/role_permissions" element={<AuthenticatedRoute element={<Role_Permissions />} />}></Route>
<Route path="/permissions" element={<AuthenticatedRoute element={<Permissions />} />}></Route>
<Route path="/payments" element={<AuthenticatedRoute element={<Payments />} />}></Route>
<Route path="/password_resets" element={<AuthenticatedRoute element={<Password_Resets />} />}></Route>
<Route path="/parks" element={<AuthenticatedRoute element={<Parks />} />}></Route>
<Route path="/park_payments" element={<AuthenticatedRoute element={<Park_Payments />} />}></Route>
<Route path="/park_monitors" element={<AuthenticatedRoute element={<Park_Monitors />} />}></Route>
<Route path="/otp" element={<AuthenticatedRoute element={<Otp />} />}></Route>
<Route path="/mobile_numbers" element={<AuthenticatedRoute element={<Mobile_Numbers />} />}></Route>
<Route path="/loyalty" element={<AuthenticatedRoute element={<Loyalty />} />}></Route>
<Route path="/lga" element={<AuthenticatedRoute element={<Lga />} />}></Route>
<Route path="/invoices" element={<AuthenticatedRoute element={<Invoices />} />}></Route>
<Route path="/groups" element={<AuthenticatedRoute element={<Groups />} />}></Route>
<Route path="/feedback" element={<AuthenticatedRoute element={<Feedback />} />}></Route>
<Route path="/employees" element={<AuthenticatedRoute element={<Employees />} />}></Route>
<Route path="/emails" element={<AuthenticatedRoute element={<Emails />} />}></Route>
<Route path="/drivers" element={<AuthenticatedRoute element={<Drivers />} />}></Route>
<Route path="/conductors" element={<AuthenticatedRoute element={<Conductors />} />}></Route>
<Route path="/city" element={<AuthenticatedRoute element={<City />} />}></Route>
<Route path="/badges" element={<AuthenticatedRoute element={<Badges />} />}></Route>
<Route path="/autoreg_vehicle_info" element={<AuthenticatedRoute element={<Autoreg_Vehicle_Info />} />}></Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Pages;

