import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IVehicles {
id:string,
registration_number:string,
owner_id:string,
park_id:string,
type:string,
operation_scope:string,
registered_by?:string,
union_id?:string,
group_id?:string
}

interface IVehiclesData {
    list?: Array<IVehicles>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IVehiclesData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const vehiclesSlice = createSlice({
    name: "vehicles",
    initialState,
    reducers: {
        setVehiclesList: (state, _action: PayloadAction<IVehiclesData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetVehiclesToInit: (state) => {
            state = initialState;
        },
        setVehiclesMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setVehiclesList, resetVehiclesToInit, setVehiclesMessage } = vehiclesSlice.actions;

export default vehiclesSlice.reducer;

