import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setPark_MonitorsMessage } from "redux/actions";
import { resetUsersToInit, setUsersList } from "redux/actions";

import { getUsers } from "services/usersService";

import { useAppDispatch } from "redux/store";
import { addPark_Monitors, updatePark_Monitors } from "services/park_monitorsService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const Park_MonitorsForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',user_id:'',name:''};
    const initialValue = action === 'edit' ? row : iValue;
    const usersData = useSelector((state: RootState) => state.users);

    useEffect(() => {
if (usersData && usersData.list && usersData.list.length === 0) {
            dispatch(resetUsersToInit());
            getUsers(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setUsersList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setPark_MonitorsMessage("No Record Found For Users"));
                }
              })
        }
}, [])
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updatePark_Monitors(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setPark_MonitorsMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setPark_MonitorsMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addPark_Monitors(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setPark_MonitorsMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setPark_MonitorsMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           name: yup.string().required('name is required'),
user_id: yup.string().required('user_id is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-success text-capitalize">{action} Park Monitors
                    <Button className="btn-icon-split float-right btn-success" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Park Monitors</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                    <div className="row md:px-4">
                    <div className="col-lg-6">
                  <Form.Group>
<label className="form -control-label">name</label>
<Form.Control type="text" name="name" className="form-control" value={formik.values.name}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.name && !!formik.errors.name}
isValid ={!!formik.touched.name && !formik.errors.name}
></Form.Control>
{
    formik.errors.name && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.name}
    </Form.Control.Feedback>
)}
</Form.Group>
</div>
<div className="col-lg-6">
<Form.Group>
<label className="form -control-label">User</label>
<Form.Control as="select"  name="user_id" className="form-control" value={formik.values.user_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.user_id && !!formik.errors.user_id}
isValid ={!!formik.touched.user_id && !formik.errors.user_id}
>
<option value={0}>Select Users </option> 
{
usersData.list.map((item, i) => {
return <option value={item.id} key={`users-${i}`}>{item.last_name}</option>
})}
</Form.Control>
{
    formik.errors.user_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.user_id}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="btn-block" style={{ marginTop: '48px' }} variant="success">Save</Button>
                    </Form.Group>
                        </div>
                    </div>
                </Form>
            </Card.Body>
        </Card>
    );
}

