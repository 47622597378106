import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IConductors {
id:string,
name:string,
union_id?:string,
group_id?:string,
email_id?:string,
mobile_id?:string,
badge_id?:string
}

interface IConductorsData {
    list?: Array<IConductors>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IConductorsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const conductorsSlice = createSlice({
    name: "conductors",
    initialState,
    reducers: {
        setConductorsList: (state, _action: PayloadAction<IConductorsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetConductorsToInit: (state) => {
            state = initialState;
        },
        setConductorsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setConductorsList, resetConductorsToInit, setConductorsMessage } = conductorsSlice.actions;

export default conductorsSlice.reducer;

