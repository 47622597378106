import { APIService } from "services";

export const getUser_Logs = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllUser_Logs(pageNo,pageSize);
    }
    else{
        try {
            res = await searchUser_Logs(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], total_count:0 }
        }
    }
    if(res && res.data && res.data.data){
    return { records:res.data.data.data, total_count:res.data.data.total }
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addUser_Logs = (data) => {
return APIService.api().post(`/user_logs`,data)
}
export const updateUser_Logs = (data) => {
return APIService.api().put(`/user_logs/${data.id}`,data)
}
export const getAllUser_Logs = (page,paginator) => {
//return APIService.api().get(`/user_logs/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/user_logs`)
}
export const getOneUser_Logs = (id) => {
return APIService.api().get(`/user_logs/${id}`)
}
export const searchUser_Logs = (searchKey,page,paginator) => {
//return APIService.api().get(`/user_logs/search/${searchKey}/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/user_logs/search/${searchKey}`)
}
export const deleteUser_Logs = (id) => {
return APIService.api().delete(`/user_logs/${id}`)
}
