import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setPark_PaymentsMessage } from "redux/actions";
import { resetParksToInit, setParksList } from "redux/actions";

import { getParks } from "services/parksService";

import { useAppDispatch } from "redux/store";
import { addPark_Payments, updatePark_Payments } from "services/park_paymentsService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const Park_PaymentsForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',park_id:'',tariff_id:'',amount:0};
    const initialValue = action === 'edit' ? row : iValue;
    const parksData = useSelector((state: RootState) => state.parks);

    useEffect(() => {
if (parksData && parksData.list && parksData.list.length === 0) {
            dispatch(resetParksToInit());
            getParks(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setParksList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setPark_PaymentsMessage("No Record Found For Parks"));
                }
              })
        }
}, [])
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updatePark_Payments(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setPark_PaymentsMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setPark_PaymentsMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addPark_Payments(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setPark_PaymentsMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setPark_PaymentsMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           amount: yup.number().required('amount is required'),
park_id: yup.string().required('park_id is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Park_Payments
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Park_Payments</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">amount</label>
<Form.Control type="text" name="amount" className="form-control" value={formik.values.amount}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.amount && !!formik.errors.amount}
isValid ={!!formik.touched.amount && !formik.errors.amount}
></Form.Control>
{
    formik.errors.amount && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.amount}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">park_id</label>
<Form.Control as="select"  name="park_id" className="form-control" value={formik.values.park_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.park_id && !!formik.errors.park_id}
isValid ={!!formik.touched.park_id && !formik.errors.park_id}
>
<option value={0}>Select Parks </option> 
{
parksData.list.map((item, i) => {
return <option value={item.id} key={`parks-${i}`}>{item.name}</option>
})}
</Form.Control>
{
    formik.errors.park_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.park_id}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

