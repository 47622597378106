import { APIService } from "services";

export const getPassword_Resets = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllPassword_Resets(pageNo,pageSize);
    }
    else{
        try {
            res = await searchPassword_Resets(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], total_count:0 }
        }
    }
    if(res && res.data && res.data.data){
    return { records:res.data.data.data, total_count:res.data.data.total }
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addPassword_Resets = (data) => {
return APIService.api().post(`/password_resets`,data)
}
export const updatePassword_Resets = (data) => {
return APIService.api().put(`/password_resets/${data.email}`,data)
}
export const getAllPassword_Resets = (page,paginator) => {
//return APIService.api().get(`/password_resets/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/password_resets`)
}
export const getOnePassword_Resets = (email) => {
return APIService.api().get(`/password_resets/${email}`)
}
export const searchPassword_Resets = (searchKey,page,paginator) => {
//return APIService.api().get(`/password_resets/search/${searchKey}/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/password_resets/search/${searchKey}`)
}
export const deletePassword_Resets = (email) => {
return APIService.api().delete(`/password_resets/${email}`)
}
