import { APIService } from "services";

export const getOtp = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllOtp(pageNo,pageSize);
    }
    else{
        try {
            res = await searchOtp(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], total_count:0 }
        }
    }
    if(res && res.data && res.data.data){
    return { records:res.data.data.data, total_count:res.data.data.total }
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addOtp = (data) => {
return APIService.api().post(`/otp`,data)
}
export const updateOtp = (data) => {
return APIService.api().put(`/otp/${data.id}`,data)
}
export const getAllOtp = (page,paginator) => {
//return APIService.api().get(`/otp/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/otp`)
}
export const getOneOtp = (id) => {
return APIService.api().get(`/otp/${id}`)
}
export const searchOtp = (searchKey,page,paginator) => {
//return APIService.api().get(`/otp/search/${searchKey}/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/otp/search/${searchKey}`)
}
export const deleteOtp = (id) => {
return APIService.api().delete(`/otp/${id}`)
}
