import { APIService } from "services";

export const getUser_Activity = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllUser_Activity(pageNo,pageSize);
    }
    else{
        try {
            res = await searchUser_Activity(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], total_count:0 }
        }
    }
    if(res && res.data && res.data.data){
    return { records:res.data.data.data, total_count:res.data.data.total }
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addUser_Activity = (data) => {
return APIService.api().post(`/user_activity`,data)
}
export const updateUser_Activity = (data) => {
return APIService.api().put(`/user_activity/${data.id}`,data)
}
export const getAllUser_Activity = (page,paginator) => {
//return APIService.api().get(`/user_activity/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/user_activity`)
}
export const getOneUser_Activity = (id) => {
return APIService.api().get(`/user_activity/${id}`)
}
export const searchUser_Activity = (searchKey,page,paginator) => {
//return APIService.api().get(`/user_activity/search/${searchKey}/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/user_activity/search/${searchKey}`)
}
export const deleteUser_Activity = (id) => {
return APIService.api().delete(`/user_activity/${id}`)
}
