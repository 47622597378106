import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IBadges {
id:string,
badge_category:string,
issued_date:Date,
expiry_date:Date,
user_id:string
}

interface IBadgesData {
    list?: Array<IBadges>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IBadgesData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const badgesSlice = createSlice({
    name: "badges",
    initialState,
    reducers: {
        setBadgesList: (state, _action: PayloadAction<IBadgesData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetBadgesToInit: (state) => {
            state = initialState;
        },
        setBadgesMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setBadgesList, resetBadgesToInit, setBadgesMessage } = badgesSlice.actions;

export default badgesSlice.reducer;

