import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUser_Sessions {
id:string,
user_id:string,
session_id:string,
ip_address:string,
user_agent:string,
payload?:string,
last_activity:Date
}

interface IUser_SessionsData {
    list?: Array<IUser_Sessions>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IUser_SessionsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const user_sessionsSlice = createSlice({
    name: "user_sessions",
    initialState,
    reducers: {
        setUser_SessionsList: (state, _action: PayloadAction<IUser_SessionsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetUser_SessionsToInit: (state) => {
            state = initialState;
        },
        setUser_SessionsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setUser_SessionsList, resetUser_SessionsToInit, setUser_SessionsMessage } = user_sessionsSlice.actions;

export default user_sessionsSlice.reducer;

