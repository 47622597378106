import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IPayments {
id:string,
user_id:string,
payable_id:string,
payable_type:string,
tariff_id:string,
amount:number,
payment_date:Date,
expiry_date:Date
}

interface IPaymentsData {
    list?: Array<IPayments>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IPaymentsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const paymentsSlice = createSlice({
    name: "payments",
    initialState,
    reducers: {
        setPaymentsList: (state, _action: PayloadAction<IPaymentsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetPaymentsToInit: (state) => {
            state = initialState;
        },
        setPaymentsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setPaymentsList, resetPaymentsToInit, setPaymentsMessage } = paymentsSlice.actions;

export default paymentsSlice.reducer;

