import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setUser_SessionsMessage } from "redux/actions";
import { resetUsersToInit, setUsersList } from "redux/actions";

import { getUsers } from "services/usersService";

import { useAppDispatch } from "redux/store";
import { addUser_Sessions, updateUser_Sessions } from "services/user_sessionsService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const User_SessionsForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',user_id:'',session_id:'',ip_address:'',user_agent:'',payload:'',last_activity:''};
    const initialValue = action === 'edit' ? row : iValue;
    const usersData = useSelector((state: RootState) => state.users);

    useEffect(() => {
if (usersData && usersData.list && usersData.list.length === 0) {
            dispatch(resetUsersToInit());
            getUsers(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setUsersList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setUser_SessionsMessage("No Record Found For Users"));
                }
              })
        }
}, [])
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateUser_Sessions(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setUser_SessionsMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setUser_SessionsMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addUser_Sessions(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setUser_SessionsMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setUser_SessionsMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           session_id: yup.string().required('session_id is required'),
ip_address: yup.string().required('ip_address is required'),
user_agent: yup.string().required('user_agent is required'),
payload: yup.string().nullable(),
last_activity: yup.date().required('last_activity is required'),
user_id: yup.string().required('user_id is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} User_Sessions
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View User_Sessions</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">session_id</label>
<Form.Control type="text" name="session_id" className="form-control" value={formik.values.session_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.session_id && !!formik.errors.session_id}
isValid ={!!formik.touched.session_id && !formik.errors.session_id}
></Form.Control>
{
    formik.errors.session_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.session_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">ip_address</label>
<Form.Control type="text" name="ip_address" className="form-control" value={formik.values.ip_address}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.ip_address && !!formik.errors.ip_address}
isValid ={!!formik.touched.ip_address && !formik.errors.ip_address}
></Form.Control>
{
    formik.errors.ip_address && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.ip_address}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">user_agent</label>
<Form.Control type="text" name="user_agent" className="form-control" value={formik.values.user_agent}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.user_agent && !!formik.errors.user_agent}
isValid ={!!formik.touched.user_agent && !formik.errors.user_agent}
></Form.Control>
{
    formik.errors.user_agent && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.user_agent}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">payload</label>
<Form.Control type="text" name="payload" className="form-control" value={formik.values.payload}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.payload && !!formik.errors.payload}
isValid ={!!formik.touched.payload && !formik.errors.payload}
></Form.Control>
{
    formik.errors.payload && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.payload}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">last_activity</label>
<Form.Control type="text" name="last_activity" className="form-control" value={formik.values.last_activity}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.last_activity && !!formik.errors.last_activity}
isValid ={!!formik.touched.last_activity && !formik.errors.last_activity}
></Form.Control>
{
    formik.errors.last_activity && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.last_activity}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">user_id</label>
<Form.Control as="select"  name="user_id" className="form-control" value={formik.values.user_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.user_id && !!formik.errors.user_id}
isValid ={!!formik.touched.user_id && !formik.errors.user_id}
>
<option value={0}>Select Users </option> 
{
usersData.list.map((item, i) => {
return <option value={item.id} key={`users-${i}`}>{item.last_name}</option>
})}
</Form.Control>
{
    formik.errors.user_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.user_id}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

