import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setVehicle_DetailsMessage } from "redux/actions";
import { resetVehiclesToInit, setVehiclesList } from "redux/actions";
import { resetUsersToInit, setUsersList } from "redux/actions";

import { getVehicles } from "services/vehiclesService";
import { getUsers } from "services/usersService";

import { useAppDispatch } from "redux/store";
import { addVehicle_Details, updateVehicle_Details } from "services/vehicle_detailsService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const Vehicle_DetailsForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',vehicle_id:'',chassis_number:'',vehicle_type:'',plate_number:'',engine_number:'',model:'',vehicle_make:'',engine_capacity:0,manufacture_year:'',registered_by:''};
    const initialValue = action === 'edit' ? row : iValue;
    const vehiclesData = useSelector((state: RootState) => state.vehicles);
const usersData = useSelector((state: RootState) => state.users);

    useEffect(() => {
if (vehiclesData && vehiclesData.list && vehiclesData.list.length === 0) {
            dispatch(resetVehiclesToInit());
            getVehicles(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setVehiclesList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setVehicle_DetailsMessage("No Record Found For Vehicles"));
                }
              })
        }
if (usersData && usersData.list && usersData.list.length === 0) {
            dispatch(resetUsersToInit());
            getUsers(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setUsersList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setVehicle_DetailsMessage("No Record Found For Users"));
                }
              })
        }
}, [])
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateVehicle_Details(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setVehicle_DetailsMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setVehicle_DetailsMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addVehicle_Details(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setVehicle_DetailsMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setVehicle_DetailsMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           chassis_number: yup.string().required('chassis_number is required'),
vehicle_type: yup.string().required('vehicle_type is required'),
plate_number: yup.string().required('plate_number is required'),
engine_number: yup.string().required('engine_number is required'),
model: yup.string().required('model is required'),
vehicle_make: yup.string().required('vehicle_make is required'),
engine_capacity: yup.number().required('engine_capacity is required'),
manufacture_year: yup.string().nullable(),
vehicle_id: yup.string().required('vehicle_id is required'),
registered_by: yup.string().required('registered_by is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-success text-capitalize">{action} Vehicle_Details
                    <Button className="btn-icon-split float-right btn-success" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Vehicle_Details</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">chassis_number</label>
<Form.Control type="text" name="chassis_number" className="form-control" value={formik.values.chassis_number}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.chassis_number && !!formik.errors.chassis_number}
isValid ={!!formik.touched.chassis_number && !formik.errors.chassis_number}
></Form.Control>
{
    formik.errors.chassis_number && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.chassis_number}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">vehicle_type</label>
<Form.Control type="text" name="vehicle_type" className="form-control" value={formik.values.vehicle_type}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.vehicle_type && !!formik.errors.vehicle_type}
isValid ={!!formik.touched.vehicle_type && !formik.errors.vehicle_type}
></Form.Control>
{
    formik.errors.vehicle_type && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.vehicle_type}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">plate_number</label>
<Form.Control type="text" name="plate_number" className="form-control" value={formik.values.plate_number}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.plate_number && !!formik.errors.plate_number}
isValid ={!!formik.touched.plate_number && !formik.errors.plate_number}
></Form.Control>
{
    formik.errors.plate_number && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.plate_number}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">engine_number</label>
<Form.Control type="text" name="engine_number" className="form-control" value={formik.values.engine_number}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.engine_number && !!formik.errors.engine_number}
isValid ={!!formik.touched.engine_number && !formik.errors.engine_number}
></Form.Control>
{
    formik.errors.engine_number && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.engine_number}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">model</label>
<Form.Control type="text" name="model" className="form-control" value={formik.values.model}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.model && !!formik.errors.model}
isValid ={!!formik.touched.model && !formik.errors.model}
></Form.Control>
{
    formik.errors.model && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.model}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">vehicle_make</label>
<Form.Control type="text" name="vehicle_make" className="form-control" value={formik.values.vehicle_make}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.vehicle_make && !!formik.errors.vehicle_make}
isValid ={!!formik.touched.vehicle_make && !formik.errors.vehicle_make}
></Form.Control>
{
    formik.errors.vehicle_make && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.vehicle_make}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">engine_capacity</label>
<Form.Control type="text" name="engine_capacity" className="form-control" value={formik.values.engine_capacity}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.engine_capacity && !!formik.errors.engine_capacity}
isValid ={!!formik.touched.engine_capacity && !formik.errors.engine_capacity}
></Form.Control>
{
    formik.errors.engine_capacity && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.engine_capacity}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">manufacture_year</label>
<Form.Control type="text" name="manufacture_year" className="form-control" value={formik.values.manufacture_year}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.manufacture_year && !!formik.errors.manufacture_year}
isValid ={!!formik.touched.manufacture_year && !formik.errors.manufacture_year}
></Form.Control>
{
    formik.errors.manufacture_year && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.manufacture_year}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">vehicle_id</label>
<Form.Control as="select"  name="vehicle_id" className="form-control" value={formik.values.vehicle_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.vehicle_id && !!formik.errors.vehicle_id}
isValid ={!!formik.touched.vehicle_id && !formik.errors.vehicle_id}
>
<option value={0}>Select Vehicles </option> 
{
vehiclesData.list.map((item, i) => {
return <option value={item.id} key={`vehicles-${i}`}>{item.registration_number}</option>
})}
</Form.Control>
{
    formik.errors.vehicle_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.vehicle_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">registered_by</label>
<Form.Control as="select"  name="registered_by" className="form-control" value={formik.values.registered_by}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.registered_by && !!formik.errors.registered_by}
isValid ={!!formik.touched.registered_by && !formik.errors.registered_by}
>
<option value={0}>Select Users </option> 
{
usersData.list.map((item, i) => {
return <option value={item.id} key={`users-${i}`}>{item.last_name}</option>
})}
</Form.Control>
{
    formik.errors.registered_by && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.registered_by}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="success">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

