import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUnions {
id:string,
name:string,
contact?:string
}

interface IUnionsData {
    list?: Array<IUnions>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IUnionsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const unionsSlice = createSlice({
    name: "unions",
    initialState,
    reducers: {
        setUnionsList: (state, _action: PayloadAction<IUnionsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetUnionsToInit: (state) => {
            state = initialState;
        },
        setUnionsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setUnionsList, resetUnionsToInit, setUnionsMessage } = unionsSlice.actions;

export default unionsSlice.reducer;

