import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ITickets {
id:string,
vehicle_id:string,
park_id:string,
park_monitor_id:string,
plate_number:string,
ticket_date:Date,
tariff_id:string,
ticket_amount:number
}

interface ITicketsData {
    list?: Array<ITickets>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: ITicketsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const ticketsSlice = createSlice({
    name: "tickets",
    initialState,
    reducers: {
        setTicketsList: (state, _action: PayloadAction<ITicketsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetTicketsToInit: (state) => {
            state = initialState;
        },
        setTicketsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setTicketsList, resetTicketsToInit, setTicketsMessage } = ticketsSlice.actions;

export default ticketsSlice.reducer;

