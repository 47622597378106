import { APIService } from "services";

export const getBadges = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllBadges(pageNo,pageSize);
    }
    else{
        try {
            res = await searchBadges(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], total_count:0 }
        }
    }
    if(res && res.data && res.data.data){
    return { records:res.data.data.data, total_count:res.data.data.total }
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addBadges = (data) => {
return APIService.api().post(`/badges`,data)
}
export const updateBadges = (data) => {
return APIService.api().put(`/badges/${data.id}`,data)
}
export const getAllBadges = (page,paginator) => {
//return APIService.api().get(`/badges/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/badges`)
}
export const getOneBadges = (id) => {
return APIService.api().get(`/badges/${id}`)
}
export const searchBadges = (searchKey,page,paginator) => {
//return APIService.api().get(`/badges/search/${searchKey}/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/badges/search/${searchKey}`)
}
export const deleteBadges = (id) => {
return APIService.api().delete(`/badges/${id}`)
}
