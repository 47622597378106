import { combineReducers } from "redux";

import template from "redux/slices/template";
import authToken from "redux/slices/auth";

import vehicles from "redux/slices/vehicles";
import vehicle_owners from "redux/slices/vehicle_owners";
import vehicle_history from "redux/slices/vehicle_history";
import vehicle_details from "redux/slices/vehicle_details";
import users from "redux/slices/users";
import user_sessions from "redux/slices/user_sessions";
import user_logs from "redux/slices/user_logs";
import user_activity from "redux/slices/user_activity";
import unions from "redux/slices/unions";
import tickets from "redux/slices/tickets";
import tariffs from "redux/slices/tariffs";
import states from "redux/slices/states";
import roles from "redux/slices/roles";
import role_permissions from "redux/slices/role_permissions";
import permissions from "redux/slices/permissions";
import payments from "redux/slices/payments";
import password_resets from "redux/slices/password_resets";
import parks from "redux/slices/parks";
import park_payments from "redux/slices/park_payments";
import park_monitors from "redux/slices/park_monitors";
import otp from "redux/slices/otp";
import mobile_numbers from "redux/slices/mobile_numbers";
import loyalty from "redux/slices/loyalty";
import lga from "redux/slices/lga";
import invoices from "redux/slices/invoices";
import groups from "redux/slices/groups";
import feedback from "redux/slices/feedback";
import employees from "redux/slices/employees";
import emails from "redux/slices/emails";
import drivers from "redux/slices/drivers";
import conductors from "redux/slices/conductors";
import city from "redux/slices/city";
import badges from "redux/slices/badges";
import autoreg_vehicle_info from "redux/slices/autoreg_vehicle_info";


const rootReducer = combineReducers({ template,authToken,vehicles,vehicle_owners,vehicle_history,vehicle_details,users,user_sessions,user_logs,user_activity,unions,tickets,tariffs,states,roles,role_permissions,permissions,payments,password_resets,parks,park_payments,park_monitors,otp,mobile_numbers,loyalty,lga,invoices,groups,feedback,employees,emails,drivers,conductors,city,badges,autoreg_vehicle_info });

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

