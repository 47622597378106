import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IMobile_Numbers {
id:string,
number:string,
user_id:string,
is_default:boolean
}

interface IMobile_NumbersData {
    list?: Array<IMobile_Numbers>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IMobile_NumbersData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const mobile_numbersSlice = createSlice({
    name: "mobile_numbers",
    initialState,
    reducers: {
        setMobile_NumbersList: (state, _action: PayloadAction<IMobile_NumbersData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetMobile_NumbersToInit: (state) => {
            state = initialState;
        },
        setMobile_NumbersMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setMobile_NumbersList, resetMobile_NumbersToInit, setMobile_NumbersMessage } = mobile_numbersSlice.actions;

export default mobile_numbersSlice.reducer;

