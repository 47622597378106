import { APIService } from "services";

export const getTickets = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllTickets(pageNo,pageSize);
    }
    else{
        try {
            res = await searchTickets(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], total_count:0 }
        }
    }
    if(res && res.data && res.data.data){
    return { records:res.data.data.data, total_count:res.data.data.total }
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addTickets = (data) => {
return APIService.api().post(`/tickets`,data)
}
export const updateTickets = (data) => {
return APIService.api().put(`/tickets/${data.id}`,data)
}
export const getAllTickets = (page,paginator) => {
//return APIService.api().get(`/tickets/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/tickets`)
}
export const getOneTickets = (id) => {
return APIService.api().get(`/tickets/${id}`)
}
export const searchTickets = (searchKey,page,paginator) => {
//return APIService.api().get(`/tickets/search/${searchKey}/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/tickets/search/${searchKey}`)
}
export const deleteTickets = (id) => {
return APIService.api().delete(`/tickets/${id}`)
}
