import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IParks {
id:string,
name:string,
parent_park_id?:string,
park_type:string,
city_id?:string
}

interface IParksData {
    list?: Array<IParks>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IParksData = {
    pageNo: 1,
    pageSize: 100,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const parksSlice = createSlice({
    name: "parks",
    initialState,
    reducers: {
        setParksList: (state, _action: PayloadAction<IParksData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetParksToInit: (state) => {
            state = initialState;
        },
        setParksMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setParksList, resetParksToInit, setParksMessage } = parksSlice.actions;

export default parksSlice.reducer;

