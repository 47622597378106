import { APIService } from "services";

export const getPayments = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllPayments(pageNo,pageSize);
    }
    else{
        try {
            res = await searchPayments(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], total_count:0 }
        }
    }
    if(res && res.data && res.data.data){
    return { records:res.data.data.data, total_count:res.data.data.total }
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addPayments = (data) => {
return APIService.api().post(`/payments`,data)
}
export const updatePayments = (data) => {
return APIService.api().put(`/payments/${data.id}`,data)
}
export const getAllPayments = (page,paginator) => {
//return APIService.api().get(`/payments/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/payments`)
}
export const getOnePayments = (id) => {
return APIService.api().get(`/payments/${id}`)
}
export const searchPayments = (searchKey,page,paginator) => {
//return APIService.api().get(`/payments/search/${searchKey}/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/payments/search/${searchKey}`)
}
export const deletePayments = (id) => {
return APIService.api().delete(`/payments/${id}`)
}
