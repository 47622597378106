import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setOtpMessage } from "redux/actions";
import { resetUsersToInit, setUsersList } from "redux/actions";

import { getUsers } from "services/usersService";

import { useAppDispatch } from "redux/store";
import { addOtp, updateOtp } from "services/otpService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const OtpForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',user_id:'',otp:'',otp_type:'',expiry_time:'',created_at:''};
    const initialValue = action === 'edit' ? row : iValue;
    const usersData = useSelector((state: RootState) => state.users);

    useEffect(() => {
if (usersData && usersData.list && usersData.list.length === 0) {
            dispatch(resetUsersToInit());
            getUsers(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setUsersList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setOtpMessage("No Record Found For Users"));
                }
              })
        }
}, [])
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateOtp(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setOtpMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setOtpMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addOtp(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setOtpMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setOtpMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           otp: yup.string().required('otp is required'),
otp_type: yup.string().required('otp_type is required'),
expiry_time: yup.date().required('expiry_time is required'),
created_at: yup.date().required('created_at is required'),
user_id: yup.string().required('user_id is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Otp
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Otp</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">otp</label>
<Form.Control type="text" name="otp" className="form-control" value={formik.values.otp}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.otp && !!formik.errors.otp}
isValid ={!!formik.touched.otp && !formik.errors.otp}
></Form.Control>
{
    formik.errors.otp && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.otp}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">otp_type</label>
<Form.Control type="text" name="otp_type" className="form-control" value={formik.values.otp_type}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.otp_type && !!formik.errors.otp_type}
isValid ={!!formik.touched.otp_type && !formik.errors.otp_type}
></Form.Control>
{
    formik.errors.otp_type && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.otp_type}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">expiry_time</label>
<Form.Control type="text" name="expiry_time" className="form-control" value={formik.values.expiry_time}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.expiry_time && !!formik.errors.expiry_time}
isValid ={!!formik.touched.expiry_time && !formik.errors.expiry_time}
></Form.Control>
{
    formik.errors.expiry_time && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.expiry_time}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">created_at</label>
<Form.Control type="text" name="created_at" className="form-control" value={formik.values.created_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.created_at && !!formik.errors.created_at}
isValid ={!!formik.touched.created_at && !formik.errors.created_at}
></Form.Control>
{
    formik.errors.created_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.created_at}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">user_id</label>
<Form.Control as="select"  name="user_id" className="form-control" value={formik.values.user_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.user_id && !!formik.errors.user_id}
isValid ={!!formik.touched.user_id && !formik.errors.user_id}
>
<option value={0}>Select Users </option> 
{
usersData.list.map((item, i) => {
return <option value={item.id} key={`users-${i}`}>{item.last_name}</option>
})}
</Form.Control>
{
    formik.errors.user_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.user_id}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

