import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUser_Logs {
id:string,
user_id:string,
log:string,
timestamp:Date
}

interface IUser_LogsData {
    list?: Array<IUser_Logs>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IUser_LogsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const user_logsSlice = createSlice({
    name: "user_logs",
    initialState,
    reducers: {
        setUser_LogsList: (state, _action: PayloadAction<IUser_LogsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetUser_LogsToInit: (state) => {
            state = initialState;
        },
        setUser_LogsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setUser_LogsList, resetUser_LogsToInit, setUser_LogsMessage } = user_logsSlice.actions;

export default user_logsSlice.reducer;

