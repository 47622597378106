import { APIService } from "services";

export const getPark_Payments = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllPark_Payments(pageNo,pageSize);
    }
    else{
        try {
            res = await searchPark_Payments(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], total_count:0 }
        }
    }
    if(res && res.data && res.data.data){
    return { records:res.data.data.data, total_count:res.data.data.total }
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addPark_Payments = (data) => {
return APIService.api().post(`/park_payments`,data)
}
export const updatePark_Payments = (data) => {
return APIService.api().put(`/park_payments/${data.id}`,data)
}
export const getAllPark_Payments = (page,paginator) => {
//return APIService.api().get(`/park_payments/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/park_payments`)
}
export const getOnePark_Payments = (id) => {
return APIService.api().get(`/park_payments/${id}`)
}
export const searchPark_Payments = (searchKey,page,paginator) => {
//return APIService.api().get(`/park_payments/search/${searchKey}/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/park_payments/search/${searchKey}`)
}
export const deletePark_Payments = (id) => {
return APIService.api().delete(`/park_payments/${id}`)
}
