import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setVehicle_HistoryMessage } from "redux/actions";
import { resetVehiclesToInit, setVehiclesList } from "redux/actions";
import { resetDriversToInit, setDriversList } from "redux/actions";

import { getVehicles } from "services/vehiclesService";
import { getDrivers } from "services/driversService";

import { useAppDispatch } from "redux/store";
import { addVehicle_History, updateVehicle_History } from "services/vehicle_historyService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const Vehicle_HistoryForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',vehicle_id:'',driver_id:'',conductor_id:'',start_date:'',end_date:''};
    const initialValue = action === 'edit' ? row : iValue;
    const vehiclesData = useSelector((state: RootState) => state.vehicles);
const driversData = useSelector((state: RootState) => state.drivers);

    useEffect(() => {
if (vehiclesData && vehiclesData.list && vehiclesData.list.length === 0) {
            dispatch(resetVehiclesToInit());
            getVehicles(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setVehiclesList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setVehicle_HistoryMessage("No Record Found For Vehicles"));
                }
              })
        }
if (driversData && driversData.list && driversData.list.length === 0) {
            dispatch(resetDriversToInit());
            getDrivers(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setDriversList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setVehicle_HistoryMessage("No Record Found For Drivers"));
                }
              })
        }
}, [])
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateVehicle_History(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setVehicle_HistoryMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setVehicle_HistoryMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addVehicle_History(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setVehicle_HistoryMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setVehicle_HistoryMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           start_date: yup.date().required('start_date is required'),
end_date: yup.date().required('end_date is required'),
vehicle_id: yup.string().required('vehicle_id is required'),
driver_id: yup.string().required('driver_id is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-success text-capitalize">{action} Vehicle_History
                    <Button className="btn-icon-split float-right btn-success" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Vehicle_History</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">start_date</label>
<Form.Control type="text" name="start_date" className="form-control" value={formik.values.start_date}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.start_date && !!formik.errors.start_date}
isValid ={!!formik.touched.start_date && !formik.errors.start_date}
></Form.Control>
{
    formik.errors.start_date && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.start_date}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">end_date</label>
<Form.Control type="text" name="end_date" className="form-control" value={formik.values.end_date}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.end_date && !!formik.errors.end_date}
isValid ={!!formik.touched.end_date && !formik.errors.end_date}
></Form.Control>
{
    formik.errors.end_date && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.end_date}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">vehicle_id</label>
<Form.Control as="select"  name="vehicle_id" className="form-control" value={formik.values.vehicle_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.vehicle_id && !!formik.errors.vehicle_id}
isValid ={!!formik.touched.vehicle_id && !formik.errors.vehicle_id}
>
<option value={0}>Select Vehicles </option> 
{
vehiclesData.list.map((item, i) => {
return <option value={item.id} key={`vehicles-${i}`}>{item.registration_number}</option>
})}
</Form.Control>
{
    formik.errors.vehicle_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.vehicle_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">driver_id</label>
<Form.Control as="select"  name="driver_id" className="form-control" value={formik.values.driver_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.driver_id && !!formik.errors.driver_id}
isValid ={!!formik.touched.driver_id && !formik.errors.driver_id}
>
<option value={0}>Select Drivers </option> 
{
driversData.list.map((item, i) => {
return <option value={item.id} key={`drivers-${i}`}>{item.name}</option>
})}
</Form.Control>
{
    formik.errors.driver_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.driver_id}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="success">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

