import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IGroups {
id:string,
name:string
}

interface IGroupsData {
    list?: Array<IGroups>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IGroupsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const groupsSlice = createSlice({
    name: "groups",
    initialState,
    reducers: {
        setGroupsList: (state, _action: PayloadAction<IGroupsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetGroupsToInit: (state) => {
            state = initialState;
        },
        setGroupsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setGroupsList, resetGroupsToInit, setGroupsMessage } = groupsSlice.actions;

export default groupsSlice.reducer;

