import { APIService } from "services";

export const getCity = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllCity(pageNo,pageSize);
    }
    else{
        try {
            res = await searchCity(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], total_count:0 }
        }
    }
    if(res && res.data && res.data.data){
    return { records:res.data.data.data, total_count:res.data.data.total }
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addCity = (data) => {
return APIService.api().post(`/city`,data)
}
export const updateCity = (data) => {
return APIService.api().put(`/city/${data.id}`,data)
}
export const getAllCity = (page,paginator) => {
//return APIService.api().get(`/city/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/city`)
}
export const getOneCity = (id) => {
return APIService.api().get(`/city/${id}`)
}
export const searchCity = (searchKey,page,paginator) => {
//return APIService.api().get(`/city/search/${searchKey}/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/city/search/${searchKey}`)
}
export const deleteCity = (id) => {
return APIService.api().delete(`/city/${id}`)
}
