import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IVehicle_Details {
id:string,
vehicle_id:string,
chassis_number:string,
vehicle_type:string,
plate_number:string,
engine_number:string,
model:string,
vehicle_make:string,
engine_capacity:number,
manufacture_year?:string,
registered_by?:string
}

interface IVehicle_DetailsData {
    list?: Array<IVehicle_Details>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IVehicle_DetailsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const vehicle_detailsSlice = createSlice({
    name: "vehicle_details",
    initialState,
    reducers: {
        setVehicle_DetailsList: (state, _action: PayloadAction<IVehicle_DetailsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetVehicle_DetailsToInit: (state) => {
            state = initialState;
        },
        setVehicle_DetailsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setVehicle_DetailsList, resetVehicle_DetailsToInit, setVehicle_DetailsMessage } = vehicle_detailsSlice.actions;

export default vehicle_detailsSlice.reducer;

