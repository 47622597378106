import { APIService } from "services";

export const getPermissions = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllPermissions(pageNo,pageSize);
    }
    else{
        try {
            res = await searchPermissions(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], total_count:0 }
        }
    }
    if(res && res.data && res.data.data){
    return { records:res.data.data.data, total_count:res.data.data.total }
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addPermissions = (data) => {
return APIService.api().post(`/permissions`,data)
}
export const updatePermissions = (data) => {
return APIService.api().put(`/permissions/${data.id}`,data)
}
export const getAllPermissions = (page,paginator) => {
//return APIService.api().get(`/permissions/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/permissions`)
}
export const getOnePermissions = (id) => {
return APIService.api().get(`/permissions/${id}`)
}
export const searchPermissions = (searchKey,page,paginator) => {
//return APIService.api().get(`/permissions/search/${searchKey}/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/permissions/search/${searchKey}`)
}
export const deletePermissions = (id) => {
return APIService.api().delete(`/permissions/${id}`)
}
