import { APIService } from "services";

export const getLoyalty = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllLoyalty(pageNo,pageSize);
    }
    else{
        try {
            res = await searchLoyalty(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], total_count:0 }
        }
    }
    if(res && res.data && res.data.data){
    return { records:res.data.data.data, total_count:res.data.data.total }
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addLoyalty = (data) => {
return APIService.api().post(`/loyalty`,data)
}
export const updateLoyalty = (data) => {
return APIService.api().put(`/loyalty/${data.id}`,data)
}
export const getAllLoyalty = (page,paginator) => {
//return APIService.api().get(`/loyalty/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/loyalty`)
}
export const getOneLoyalty = (id) => {
return APIService.api().get(`/loyalty/${id}`)
}
export const searchLoyalty = (searchKey,page,paginator) => {
//return APIService.api().get(`/loyalty/search/${searchKey}/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/loyalty/search/${searchKey}`)
}
export const deleteLoyalty = (id) => {
return APIService.api().delete(`/loyalty/${id}`)
}
