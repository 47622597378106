import { APIService } from "services";

export const getRole_Permissions = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllRole_Permissions(pageNo,pageSize);
    }
    else{
        try {
            res = await searchRole_Permissions(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], total_count:0 }
        }
    }
    if(res && res.data && res.data.data){
    return { records:res.data.data.data, total_count:res.data.data.total }
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addRole_Permissions = (data) => {
return APIService.api().post(`/role_permissions`,data)
}
export const updateRole_Permissions = (data) => {
return APIService.api().put(`/role_permissions/${data.role_id}`,data)
}
export const getAllRole_Permissions = (page,paginator) => {
//return APIService.api().get(`/role_permissions/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/role_permissions`)
}
export const getOneRole_Permissions = (role_id) => {
return APIService.api().get(`/role_permissions/${role_id}`)
}
export const searchRole_Permissions = (searchKey,page,paginator) => {
//return APIService.api().get(`/role_permissions/search/${searchKey}/?page=${page}&paginator=${paginator}`)
return APIService.api().get(`/role_permissions/search/${searchKey}`)
}
export const deleteRole_Permissions = (role_id) => {
return APIService.api().delete(`/role_permissions/${role_id}`)
}
